import { Search } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { IconButton, Select, MenuItem, Autocomplete, TextField } from "@mui/material";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slide.css";
import slide1 from "../images/beach.jpg";
import slide2 from "../images/ella.jpg";
import slide3 from "../images/col.jpg";

const geoNamesUsername = "christhurasa_roshan"; 
const geoNamesApiUrl = `http://api.geonames.org/searchJSON?country=LK&featureClass=P&maxRows=1000&username=${geoNamesUsername}`;

const Slide = () => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [places, setPlaces] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const response = await axios.get(geoNamesApiUrl);
        const placeNames = response.data.geonames.map((place) => place.name);
        setPlaces(placeNames);
      } catch (error) {
        console.error("Error fetching places:", error);
      }
    };

    fetchPlaces();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          color: "#fff",
          bottom: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          zIndex: 3,
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  const images = [slide1, slide2, slide3];

  const handleSearch = () => {
    if (category) {
      const categoryLower = category.toLowerCase();
      navigate(`/properties/category/${categoryLower}?location=${encodeURIComponent(search)}`);
    }
  };
  

  return (
    <div className="slide">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slide-container">
            <img src={image} alt={`Slide ${index + 1}`} className="slide-image" />
          </div>
        ))}
      </Slider>
      <div className="static-content">
        <h1 className="slide-text">
          Welcome to Explore Lanka Tourism! <br /> Where Every Corner Tells a Story of Serenity and Adventure
        </h1>
        <div className="navbar_search">
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            style={{ width: "400px", backgroundColor: "#97bfdc" }}
          >
            <MenuItem value="" disabled>
              What are you looking for...
            </MenuItem>
            <MenuItem value="Hotel">Hotel</MenuItem>
            <MenuItem value="Restaurant">Restaurant</MenuItem>
            <MenuItem value="Activity">Activities</MenuItem>
            <MenuItem value="vehiclerent">Vehicle Rent</MenuItem>
          </Select>
          <Autocomplete
            options={places}
            getOptionLabel={(option) => option}
            value={search}
            onChange={(event, newValue) => setSearch(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Where are you looking for..."
                style={{ width: "400px", backgroundColor: "#97bfdc" }}
              />
            )}
          />
          <IconButton
            disabled={search === "" || category === ""}
            style={{ backgroundColor: '#0C4670', padding: '10px', borderRadius: '50%', color: '#fff' }}
            onClick={handleSearch} 
          >
            <Search />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Slide;

