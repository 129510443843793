import { useEffect, useState } from "react";
import { facilities } from "../data";
import "../styles/ResLisDetails.css";
import "../styles/ListingDetails.css";
import { Link,  useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { DateRange } from "react-date-range";
import Loader from "../components/Loader";
// import { useSelector } from "react-redux";
import { FaCheck, FaStreetView } from 'react-icons/fa';
import Footer from "../components/Footer";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Navbar2 from '../components/Navbar2';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

export const RestaurantListingDetails = () => {
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false); 
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // Comment section
  const creatorId = useSelector((state) => state.user?._id || '');
  const [formComment, setFormComment] = useState({
    comment: "",
    creator: creatorId,
  });
  const navigate = useNavigate();

  const handleChangeComment = (e) => {
    const { name, value } = e.target;
    setFormComment((prevFormComment) => ({
      ...prevFormComment,
      [name]: value,
    }));
  };

  const getComments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/restaurants/${listingId}/comments`, {
        method: "GET",
      });

      const data = await response.json();
      setComments(data);
    } catch (err) {
      console.log("Fetch Comments Failed", err.message);
    }
  };
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    setSubmitting(true); // Show the loader
    try {
      const response = await fetch(`${API_BASE_URL}/restaurants/${listingId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formComment),
      });

      if (response.ok) {
        const newComment = await response.json();
        setComments((prevComments) => [...prevComments, newComment]);
        setFormComment({ comment: "", creator: creatorId });
      } else {
        console.error("Failed to submit comment");
      }
    } catch (err) {
      console.error("Submit Comment failed", err.message);
    } finally {
      setSubmitting(false); 
      await getListingDetails(); 
      await getComments(); 
    }
  };

  useEffect(() => {
    getListingDetails();
    getComments();
  }, []);

  


  const getListingDetails = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/restaurants/${listingId}`,
        {
          method: "GET",
        }
      );

      const data = await response.json();
      setListing(data);
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listing Details Failed", err.message);
    }
  };

  useEffect(() => {
    getListingDetails();
  }, []);
  const generateMapUrl = (address, city, province) => {
    const query = encodeURIComponent(`${address}, ${city}, ${province}`);
    return `https://www.google.com/maps/search/?api=1&query=${query}`;
  };

  const handleGalleryClick = (index) => {
    setPhotoIndex(index);
    setShowGallery(true);
  };

  const handleDeleteListing = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/restaurants/${listingId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        Swal.fire({
          title: "Delete Successful",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            popup: 'swal-custom'
          }
        }).then(() => {
          navigate("/properties/category/restaurant");
        });
      } else {
        console.error("Failed to delete listing");
      }
    } catch (err) {
      console.error("Delete Listing failed", err.message);
    }
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = listing.contactNumber;
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  
  

  return  loading || submitting ? (
    <Loader />
  ) : (
    <>
      <Navbar2 />
      
        <div className="listing-details">
                <div className="photos">
                    {/* Main photo */}
                    <img
                        src={listing.imgUrls[0]}
                        alt="listing photo"
                        className="main-photo"
                        onClick={() => handleGalleryClick(0)}// Open gallery when main photo is clicked
                    />
                    {/* Sub photos */}
                    <div className="sub-photos">
            {listing.imgUrls.slice(1, 5).map((item, index) => (
              <div key={index} className="sub-photo-wrapper" onClick={() => handleGalleryClick(index + 1)}>
                <img
                  src={item}
                  alt="listing photo"
                  className="sub-photo"
                />
                {index === 3 && listing.imgUrls.length > 5 && (
                  <div className="more-photos-text">
                    +{listing.imgUrls.length - 5} more
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="hotel-info-box">
          <h2>{listing.title}</h2>
          <p className="address"><a  className="map-link" href={generateMapUrl(listing.streetAddress, listing.city, listing.province)} target="_blank" rel="noopener noreferrer">
            {listing.streetAddress}, {listing.city}, {listing.province} Province - <span className="see-map">SEE MAP</span></a></p>
            <p className="address">
            Hosted by {listing.creator.firstName} {listing.creator.lastName}
          </p>
         
          <div className="callview">
        <h2 className="address"><FontAwesomeIcon icon={faPhone} /> : +{listing.contactNumber}</h2></div>
  
        <div className="views">  <p><FaStreetView/>:{listing.placeView} Available</p></div><hr/>
        <p className="address">{listing.description}</p> <br/>

          {/* <div></div> */}
        </div>
        <div className="highlights">
        <h4>Know Our Highlights</h4><br/>
        <p className="special">{listing.highlightDesc}</p>

        </div>

        
        <div className="offers-hotel">
            <h3>This place offers</h3>
            <div className="amenities">
              {listing.amenities[0].split(",").map((item, index) => (
                <div className="facility" key={index}>
                  <div className="facility_icon">
                    {
                      facilities.find((facility) => facility.name === item)
                        ?.icon
                    }
                  </div>
                  <p>{item}</p>
                </div>
              ))}
            </div>


          </div>

          <div className="menu-items">
            <h3>Explore Our Menu</h3>

          <div className="photos">
          {listing.menuImgUrls?.map((item) => (
            <img
              src={item}
              alt="listing photo"
            />
          ))}
        </div>
        </div>
        <div className="cusdines">
       
  <div className="box">
        <ul>
        <h3>Our Special Cuisines</h3><br/>
        {listing.cuisines[0].split(",").map((cuisine, index) => (
          <li key={index}><FaCheck style={{ color: 'green' }} />{cuisine.trim()}</li> 
        ))}
      </ul>
  </div>
  <div className="box">
  <ul>
  <h3>Our Special Dinings</h3><br/>
        {listing.styDinings[0].split(",").map((styDining, index) => (
          <li key={index}><FaCheck style={{ color: 'green' }} />{styDining.trim()}</li> 
        ))}
      </ul>
  </div>
</div>
        

       

        
        



        
        
       
        <hr />
        <div className="comments-section">
          <h2>Public Comments</h2>
          {comments.length === 0 ? (
            <p style={{color:"grey"}}>No comments yet</p>
          ) 
          : (
            comments.map((comment, index) => (
              <div key={index} className="comment-box">
                <div className="comment">
                  <div className="commant-profile">
                    {comment.creator.profileImagePath ? (
                      <img
                        src={`http://localhost:8000/${comment.creator.profileImagePath.replace(
                          "public",
                          ""
                        )}`}
                        alt="profile"
                      />
                    ) : (
                      <img
                        src="http://localhost:8000/default-profile.png"
                        alt="default profile"
                      />
                    )}
                    <h4>by: {comment.creator.firstName} {comment.creator.lastName}</h4>
                  </div>
                  <h3>{comment.comment}</h3>
                  <h5>{comment.createdAt}</h5>
                </div>
              </div>
            ))
          )
          }
        </div>
        <hr />
        {creatorId ? (
            creatorId === listing.creator._id ? (
              <div className="creator-actions">
                <button className="delete_btn" onClick={handleDeleteListing}>
                  Delete Your Hotel
                </button>
              </div>
            ) : (
              <div className="review-box">
                <h2>Write Your Comments</h2>
                <form onSubmit={handleSubmitComment}>
                  <textarea
                    type="text"
                    placeholder="Give Your Reviews"
                    name="comment"
                    value={formComment.comment}
                    onChange={handleChangeComment}
                  />
                  <button className="comment_btn" type="submit">
                    Submit Your Comment
                  </button>
                </form>
              </div>
            )
          ) : (
            <div className="review-box">
              <h4>Sign in to write Your comment</h4>
              {/* Your login component or message for users to sign in */}
            </div>
          )}
        {showGallery && (
  <Lightbox className="lightbox"
    mainSrc={listing.listingPhotoPaths[photoIndex] ? `http://localhost:8000/${listing.listingPhotoPaths[photoIndex].replace("public", "")}` : ''}
    nextSrc={`http://localhost:8000/${listing.listingPhotoPaths[(photoIndex + 1) % listing.listingPhotoPaths.length].replace("public", "")}`}
    prevSrc={`http://localhost:8000/${
      listing.listingPhotoPaths[(photoIndex + listing.listingPhotoPaths.length - 1) % listing.listingPhotoPaths.length].replace("public", "")
    }`}
    onCloseRequest={() => setShowGallery(false)}
    onMovePrevRequest={() => setPhotoIndex((photoIndex + listing.listingPhotoPaths.length - 1) % listing.listingPhotoPaths.length)}
    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % listing.listingPhotoPaths.length)}
    reactModalStyle={{ overlay: { zIndex: 9999 } }} // Adjust the z-index if necessary
  // Adjust the height of the Lightbox
  />
)}      
          </div>

      <Footer />
      <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </div>
    </>
  );
};

export default RestaurantListingDetails;
