import { useState, useEffect } from "react";
import "../styles/List.css";
import { facilities } from "../data";
import CommonSection from "../shared/commonSection/CommonSection";
import Navbar from "../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { setListings } from "../redux/state";
import Loader from "../components/Loader";
import ListingCard from "../components/ListingCard";
import Footer from "../components/Footer";

const CategoryPage = () => {
  const [loading, setLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const listings = useSelector((state) =>
    Array.isArray(state.listings) ? state.listings : []
  );

  const [filters, setFilters] = useState({
    place: "",
    maxPrice: 100000,
    type: "",
    starRating: 0,
    facilities: [],
    placeView: [], 
    priceRange: { min: 0, max: 100000 }
  });

  const getFeedListings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/properties/search/all`, {
        method: "GET",
      });

      const data = await response.json();
      console.log("Fetched Data:", data);
      if (Array.isArray(data)) {
        const approvedListings = data.filter(listing => listing.approve === true);
        dispatch(setListings({ listings: approvedListings }));
      } else {
        console.error("Expected an array but got", typeof data);
      }
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listings Failed", err.message);
    }
  };

  useEffect(() => {
    getFeedListings();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // const handlePriceChange = (e) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     maxPrice: Number(e.target.value),
  //   }));
  // };

  const handleTypeChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      type: prevFilters.type === e.target.value ? "" : e.target.value,
    }));
  };

  const handleFacilitiesChange = (e) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      if (checked) {
        return {
          ...prevFilters,
          facilities: [...prevFilters.facilities, value],
        };
      } else {
        return {
          ...prevFilters,
          facilities: prevFilters.facilities.filter(
            (facility) => facility !== value
          ),
        };
      }
    });
  };

  const handleStarRatingChange = (e) => {
    const value = Number(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      starRating: prevFilters.starRating === value ? 0 : value,
    }));
  };
  const handlePlaceViewChange = (e) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      const newPlaceView = checked
        ? [...prevFilters.placeView, value]
        : prevFilters.placeView.filter((view) => view !== value);
  
      return {
        ...prevFilters,
        placeView: newPlaceView,
      };
    });
  };
  const handlePriceChange = (e) => {
    const { value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      maxPrice: Number(value),
    }));
  };
  
  

  const filteredListings = listings.filter((listing) => {
    // Check if the district matches the selected place filter
    const placeMatches =
      !filters.place ||
      listing.city.toLowerCase().includes(filters.place.toLowerCase());
  
    // Check if any room type matches the selected type filter
    const typeMatches =
      !filters.type || 
      (listing.rooms && listing.rooms.some((room) => room.type === filters.type));
  
    // Check if the star rating matches the selected rating filter
    const starRatingMatches =
      filters.starRating === 0 || listing.starRating >= filters.starRating;
  
    // Check if any selected facility matches any of the amenities of the listing
    const facilitiesMatches =
      filters.facilities.length === 0 ||
      (listing.rooms && listing.rooms.some((room) =>
        filters.facilities.some((facility) =>
          room.amenities.some((amenity) => {
            const amenitiesArray = amenity.split(",").map((a) => a.trim());
            return amenitiesArray.includes(facility);
          })
        )
      ));
  
    // Check if the room price falls within the selected price range
    const priceMatches =
      listing.rooms && listing.rooms.some((room) => room.price <= filters.maxPrice);
  
    // Check if the place view matches the selected place view filter
    const placeViewMatches =
      filters.placeView.length === 0 ||
      filters.placeView.includes(listing.placeView);
  
    // Return the combined filter conditions
    return placeMatches && typeMatches && starRatingMatches && facilitiesMatches && priceMatches && placeViewMatches;
  });
  
  

  return loading ? (
    <Loader />
  ) : (
    <>
      <Navbar />
      <div>
        <CommonSection title={"Hotels"} />
      </div>
      <div className="content">
        <div className="filter-box">
          <h3 className="head">Filter Your Choices</h3>
          <div className="filter-item">
            <label htmlFor="place"><h3>District</h3></label>
            <input
              type="text"
              id="place"
              name="place"
              value={filters.place}
              onChange={handleFilterChange}
            
            />
          </div>

          <div className="filter-item">
          <label><h3>Price Range</h3></label>
          <div className="price-range">
            <input
              type="range"
              min="0"
              max="100000"
              value={filters.maxPrice}
              onChange={handlePriceChange}
             
            />
            <span>{filters.maxPrice}</span>
          </div>
        </div>

          <div className="filter-item">
            <label><h3>Star Rating</h3></label>
            <div className="star-rating-options">
              <label>
                <input
                  type="checkbox"
                  value="1"
                  checked={filters.starRating === 1}
                  onChange={handleStarRatingChange}
                  className="checkbox-large"
                />
                
                ★
              </label>
              <label>
                <input
                  type="checkbox"
                  value="2"
                  checked={filters.starRating === 2}
                  onChange={handleStarRatingChange}
                  className="checkbox-large"
                />
                ★★
              </label>
              <label>
                <input
                  type="checkbox"
                  value="3"
                  checked={filters.starRating === 3}
                  onChange={handleStarRatingChange}
                  className="checkbox-large"
                />
                ★★★
              </label>
              <label>
                <input
                  type="checkbox"
                  value="4"
                  checked={filters.starRating === 4}
                  onChange={handleStarRatingChange}
                  className="checkbox-large"
                />

                ★★★★
              </label>
              <label>
                <input
                  type="checkbox"
                  value="5"
                  checked={filters.starRating === 5}
                  onChange={handleStarRatingChange}
                  className="checkbox-large"
                />
                ★★★★★
              </label>
            </div>
          </div>

          <div className="filter-item">
            <label><h3>Type</h3></label>
            <div className="type-option">
              <input
                type="checkbox"
                id="An entire place"
                name="type"
                value="An entire place"
                checked={filters.type === "An entire place"}
                onChange={handleTypeChange}
                className="checkbox-large"
              />
              <label htmlFor="An entire place">An entire place</label>
            </div>
            <div className="type-option">
              <input
                type="checkbox"
                id="Room(s)"
                name="type"
                value="Room(s)"
                checked={filters.type === "Room(s)"}
                onChange={handleTypeChange}
                className="checkbox-large"
              />
              <label htmlFor="Room(s)">Room(s)</label>
            </div>
            <div className="type-option">
              <input
                type="checkbox"
                id="A Shared Room"
                name="type"
                value="A Shared Room"
                checked={filters.type === "A Shared Room"}
                onChange={handleTypeChange}
                className="checkbox-large"
              />
              <label htmlFor="A Shared Room">A Shared Room</label>
            </div></div>
            <div className="filter-item">
  <label><h3>Place View</h3></label>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Beach View"
      name="placeView"
      value="beachview"
      checked={filters.placeView.includes("beachview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Beach View">Beach View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Lake View"
      name="placeView"
      value="Lakeview"
      checked={filters.placeView.includes("Lakeview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Lake View">Lake View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="City View"
      name="placeView"
      value="Cityview"
      checked={filters.placeView.includes("Cityview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="City View">City View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Island View"
      name="placeView"
      value="Islandview"
      checked={filters.placeView.includes("Islandview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Island View">Island View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Mountain View"
      name="placeView"
      value="Mountainview"
      checked={filters.placeView.includes("Mountainview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Mountain View">Mountain View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Tiny Homes"
      name="placeView"
      value="TinyHomes"
      checked={filters.placeView.includes("TinyHomes")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Tiny Homes">Tiny Homes</label>
  </div>
</div>


            <div className="filter-item">
              <label><h3>Facilities</h3></label>
              {facilities.map((facility) => (
                <div key={facility.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={facility.name}
                    name="facilities"
                    value={facility.name}
                    onChange={handleFacilitiesChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={facility.name}>{facility.name}</label>
                </div>
              ))}
            </div>
          
        </div>
        <div className="list">
          {filteredListings.map(
            ({
              _id,
              creator,
              hoteltitle,
              listingPhotoPaths,
              starRating,
              placeView,
              city,
              hotelPhotos,
              province,
              streetAddress,
              category,
            }) => (
              <ListingCard
                key={_id}
                listingId={_id}
                hoteltitle={hoteltitle}
                creator={creator}
                starRating={starRating}
                hotelPhotos={hotelPhotos}
                placeView={placeView}
                listingPhotoPaths={listingPhotoPaths}
                streetAddress={streetAddress}
                city={city}
                province={province}
                category={category}
              />
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryPage;
