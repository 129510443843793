import "../styles/CreateActivity.css";
import { activitytypes, facilities,activityPackages } from "../data";
import { RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import { BiTrash } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import app from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import Navbar2 from "../components/Navbar2";
import cycling from "../images/cycling.png";
import Swal from "sweetalert2";

export const CreateActivies = () => {
  const { listingId } = useParams(); // Get the listingId from params
  const [type, setType] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [category] = useState("Activities");
  const [formLocation, setFormLocation] = useState({
    streetAddress: "",
    city: "",
    province: "",
  });
  
  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setFormLocation({
      ...formLocation,
      [name]: value,
    });
  };

  const [guestCount, setGuestCount] = useState(0);
  const [amenities, setAmenities] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]); // New state to keep track of uploaded images
  const [imgPerc, setImgPerc] = useState("");
  const [inputs, setInputs] = useState({});
  const [imageUploaded, setImageUploaded] = useState(false);

  const [contactNumber, setContactNumber] = useState("+94");

  const handleChangeContactNumber = (e) => {
    const { value } = e.target;
    if (value.startsWith("+94")) {
        setContactNumber(value);
    } else if (value === "" || value === "+") {
        setContactNumber("+94");
    }
  };

  const handleSelectAmenities = (activityPackage) => {
    if (amenities.includes(activityPackage)) {
      setAmenities((prevAmenities) =>
        prevAmenities.filter((option) => option !== activityPackage)
      );
    } else {
      setAmenities((prev) => [...prev, activityPackage]);
    }
  };

  const [formDescription, setFormDescription] = useState({
    hoteltitle:"",
    title: "",
    description: "",
    highlight: "",
    highlightDesc: "",
    price: "",
  });

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;
    setFormDescription({
      ...formDescription,
      [name]: value,
    });
  };

  const creatorId = useSelector((state) => state.user._id);
  const navigate = useNavigate();

  // Fetch the listing details if listingId exists
  useEffect(() => {
    if (listingId) {
      const fetchListingDetails = async () => {
        try {
          const response = await fetch(`http://localhost:8000/activities/${listingId}`, {
            method: "GET",
          });
          const data = await response.json();
          // Populate form fields with the fetched data
          setType(data.type);
          setFormLocation({
            streetAddress: data.streetAddress,
            city: data.city,
            province: data.province,
          });
          setGuestCount(data.guestCount);
          setAmenities(data.amenities);
          setContactNumber(data.contactNumber);
          setFormDescription({
            title: data.title,
            description: data.description,
            highlight: data.highlight,
            highlightDesc: data.highlightDesc,
            price: data.price,
          });
          setPhotos(data.listingPhotoPaths.map(photo => ({
            name: photo,
            url: `http://localhost:8000/${photo.replace("public", "")}`
          })));
        } catch (err) {
          console.error("Fetch Listing Details Failed", err.message);
        }
      };
      fetchListingDetails();
    }
  }, [listingId]);

  // Use a ref to keep track of the files being uploaded to avoid re-uploading the same files
  const imagesRef = useRef([]);

  useEffect(() => {
    if (images.length > 0) {
      const newImages = images.filter(image => !imagesRef.current.includes(image));
      newImages.forEach(image => {
        imagesRef.current.push(image);
        uploadFile(image);
      });
    }
  }, [images]);

  const uploadFile = (file) => {
    const storage = getStorage();
    const folder = "images/";
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImgPerc(Math.round(progress));
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        switch (error.code) {
          case 'storage/unauthorized':
            console.log(error);
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            const updatedImgUrls = prev.imgUrls ? [...prev.imgUrls, downloadURL] : [downloadURL];
            return {
              ...prev,
              imgUrls: updatedImgUrls,
            };
          });
          setUploadedImages((prev) => [...prev, downloadURL]);
        });
      }
    );
  };

  const handlePost = async (e) => {
    e.preventDefault();
    
    

    try {
      const listingForm = new FormData();
      listingForm.append("creator", creatorId);
      listingForm.append("category", category);
      listingForm.append("type", type);
      listingForm.append("streetAddress", formLocation.streetAddress);
      listingForm.append("city", formLocation.city);
      listingForm.append("province", formLocation.province);
      listingForm.append("guestCount", guestCount);
      listingForm.append("amenities", amenities);
      listingForm.append("title", formDescription.title);
      listingForm.append("description", formDescription.description);
      listingForm.append("price", formDescription.price);
      listingForm.append("contactNumber", contactNumber);
      listingForm.append("approve", false);
      inputs.imgUrls.forEach((url, index) => {
        listingForm.append(`imgUrls[${index}]`, url);
      });
      

      const response = await fetch(
        listingId ? `${API_BASE_URL}/activities/${listingId}` : `${API_BASE_URL}/activities/create/activity`,
        {
          method: listingId ? "PUT" : "POST",
          body: listingForm,
        }
      );

      if (response.ok) {
        Swal.fire({
          title: "Your Property has been created Successfully! It is under Review for approval",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            popup: 'swal-custom'
          }
        }).then(() => {
          navigate("/");
        });
      } else {
        console.error("Failed to create/update listing");
      }
    } catch (err) {
      console.error("Publish Listing failed", err.message);
    }
  };

  const handleRemoveImage = (index) => {
    if (!inputs.imgUrls || !inputs.imgUrls[index]) {
      console.error("Image URL not found for index:", index);
      return;
    }
    const storagePath = inputs.imgUrls[index];
    const storage = getStorage();
    const imageRef = ref(storage, storagePath);

    deleteObject(imageRef).then(() => {
      console.log('File deleted successfully from Firebase Storage');
      // Update the state to remove the image from the list
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
      setInputs((prev) => {
        const updatedImgUrls = prev.imgUrls.filter((_, i) => i !== index);
        return { ...prev, imgUrls: updatedImgUrls };
      });
      setUploadedImages((prev) => prev.filter((_, i) => i !== index));
      imagesRef.current = imagesRef.current.filter((_, i) => i !== index);
      if (images.length === 1) setImageUploaded(false);
      setImgPerc("");
    }).catch((error) => {
      console.error('Error deleting file from Firebase Storage:', error);
    });
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...newFiles]);
  };
  
  return (
    <>
      <Navbar2 />

      <div className="create-activities">
       
          <div className="heading">
              <div><h3>PUBLISH YOUR ACTIVITIES</h3>
              <p>Showcase your culinary delights to the world. Join our platform to reach more customers and grow your business.<span className="res-highlight">All information is required unless marked optional.</span></p></div>
              <img src={cycling}/>
          </div>
        <form onSubmit={handlePost}>
        <div className="create-activities_step1">
        <div className="step-heading">
        <div className="step-number">STEP:01</div>
        <div className="step-description">TELL US ABOUT YOUR ACTIVITY</div>  
      </div>
            
      <div className="description-box">
            <h3>FOR WHICH ACTIVITY ARE YOU SUPPORTING?</h3>
            <div className="type-list">
              {activitytypes?.map((item, index) => (
                <div
                  className={`type ${type === item.name ? "selected" : ""}`}
                  key={index}
                  onClick={() => setType(item.name)}
                >
                  <div className="type_icon">{item.icon}</div>
                  <div className="type_text">
                    <h4>{item.name}</h4>
                  </div>
                  
                </div>
              ))}
            </div></div>

            <div className="add-location">
          <h3>WHERE IS YOUR PLACE LOCATED?</h3>
          <div className="full">
            <div className="location">
              <p>Street Address</p>
              <input
                type="text"
                placeholder="Street Address"
                name="streetAddress"
                value={formLocation.streetAddress}
                onChange={handleChangeLocation}
                required
              />
            </div>
          </div>
          <div className="half">
            <div className="location">
              <p>Province</p>
              <input
                type="text"
                placeholder="Province"
                name="province"
                value={formLocation.province}
                onChange={handleChangeLocation}
                required
              />
            </div>
            <div className="location">
              <p>City</p>
              <input
                type="text"
                placeholder="City"
                name="city"
                value={formLocation.city}
                onChange={handleChangeLocation}
                required
              />
            </div>
          </div></div>
          <div className="description-box">

          <h3>WHAT MAKES YOUR ACTIVITY ATTRACTIVE & EXCITING?</h3>
          <div className="description">
            <p>Title</p>
          <input
            type="text"
            placeholder="Title"
            name="title"
            value={formDescription.title}
            onChange={handleChangeDescription}
            required
          />
          
          <div className="description-box-1">
          <p>Description</p>
          <div className="hoteltitle">
          <textarea
            type="text"
            placeholder="Description"
            name="description"
            value={formDescription.description}
            onChange={handleChangeDescription}
            required
          /></div>
          </div> </div></div>


         
          </div>
          <div className="create-listing_step2">
          <div className="step-heading">
<div className="step-number">STEP:02</div>
<div className="step-description">MAKE YOUR ACTIVITIES STANDS OUT</div>

</div>
          <hr />
          <div className="description-box">
          <h3>SHARE SOME BASICS ABOUT YOUR ACTIVITIES</h3>
          <div className="basics">
            <div className="basic">
              <p>Person's Count</p>
              <div className="basic_count">
                <RemoveCircleOutline
                  onClick={() => {
                    guestCount > 1 && setGuestCount(guestCount - 1);
                  }}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "gray" },
                  }}
                />
                <p>{guestCount}</p>
                <AddCircleOutline
                  onClick={() => setGuestCount(guestCount + 1)}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
              </div>
            </div>
            <div className="description">
          
          <span>Rs.</span>
          <input
            type="number"
            placeholder="Price per night"
            name="price"
            value={formDescription.price}
            onChange={handleChangeDescription}
            className="price"
            required
          />
        </div>
          </div></div>

          <div className="description-box">
            <h3>UPLOAD PHOTOS</h3>
            <div>
              {images.length > 0 && (
                <div className="uploaded-image-container">
                  {images.map((image, index) => (
                    <div className="uploaded-image" key={index}>
                      {imgPerc > 0 && "Uploading: " + imgPerc + "%"}
                      <img src={URL.createObjectURL(image)} alt="Uploaded" width="100" />
                      <button type="button" onClick={() => handleRemoveImage(index)}>
                        <BiTrash style={{ color: 'red', fontSize: '18px' }} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                id="img"
                multiple
                onChange={handleFileChange}
              />
            </div>
          </div>


          <div className="description-box">
          <h3>TELL WHAT YOUR PLACE HAS OFFER</h3>
          <div className="amenities">
            {activityPackages?.map((item, index) => (
              <div
                className={`facility ${
                  amenities.includes(item.name) ? "selected" : ""
                }`}
                key={index}
                onClick={() => handleSelectAmenities(item.name)}
              >
                <div className="facility_icon">{item.icon}</div>
                <p>{item.name}</p>
              </div>
            ))}  
        </div></div>
        <div className="description-box">
        <h3>CONTACT NUMBER</h3>
              <div className="location">
                  <input
                       type="text"
                        placeholder="+94"
                         name="contactNumber"
                         value={contactNumber}
                         onChange={handleChangeContactNumber}
                        required
                          />
             </div></div>
         
        </div>
        <button className="submit_btn" type="submit" disabled={images.length < 5} >
          {listingId ? "Update Listing" : "PUBLISH ACTIVITY"}
        </button>
      </form>
    </div>

      <Footer />
    </>
  );
};

export default CreateActivies;
