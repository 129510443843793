import { useState, useEffect, useRef } from "react";
import "../styles/ListingCard.css";
import {
  ArrowForwardIos,
  ArrowBackIosNew,
  Favorite,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setWishList } from "../redux/state";


const RestaurantCard = ({
    listingId,
    creator,
    listingPhotoPaths,
    streetAddress,
    amenities,
    imgUrls,
    menuImgUrls,
    cuisines,
    styDinings,
    city,
    province,
    title,
    category,
    placeView,
    openingTime,
    closingTime,
    availabilityPeriod,
}) => {
    /* SLIDER FOR IMAGES */
    const [currentIndex, setCurrentIndex] = useState(0);
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;  
    const goToPrevSlide = () => {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + imgUrls.length) % imgUrls.length
      );
    };
  
    const goToNextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imgUrls.length);
    };
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    /* ADD TO WISHLIST */
    const user = useSelector((state) => state.user);
    const wishList = user?.wishList || [];
  
    const isLiked = wishList?.find((item) => item?._id === listingId);
    const titleRef = useRef(null);
    const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
    useEffect(() => {
      const checkTitleOverflow = () => {
        if (titleRef.current) {
          const isOverflowing =
            titleRef.current.scrollWidth > titleRef.current.clientWidth;
          setIsTitleOverflowing(isOverflowing);
        }
      };
  
      window.addEventListener("resize", checkTitleOverflow);
      checkTitleOverflow(); // Initial check
      return () => {
        window.removeEventListener("resize", checkTitleOverflow);
      };
    }, []);

    const truncatedTitle = isTitleOverflowing
    ? title.slice(0, 15) + "..." // Adjust the number of characters as needed
    : title;

  const handleClick = () => {
    navigate(`/restaurants/detail/${listingId}`);
  };
  
  
    const patchWishList = async () => {
      if (user?._id !== creator._id) {
      const response = await fetch(
        `${API_BASE_URL}/users/${user?._id}/${listingId}`,
        {
          method: "PATCH",
          header: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      dispatch(setWishList(data.wishList));
    } else { return }
    };

const handleFavoriteClick = (e) => {
      e.stopPropagation();
      patchWishList();
      // Optimistically update the UI
      const updatedWishList = isLiked
        ? wishList.filter((item) => item._id !== listingId)
        : [...wishList, { _id: listingId }];
      dispatch(setWishList(updatedWishList));
    };    
  
    return (
      <div className="listing-container">
      <div
        className="listing-card"
        onClick={() => {
          navigate(`/restaurants/detail/${listingId}`);
        }}
      >
        <div className="slider-container">
          <div
            className="slider"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {imgUrls?.map((photo, index) => (
              <div key={index} className="slide">
                <img
                  src={photo}
                  alt={`photo ${index + 1}`}
                  
                />
                <div
                  className="prev-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToPrevSlide(e);
                  }}
                >
                  <ArrowBackIosNew sx={{ fontSize: "15px" }} />
                </div>
                <div
                  className="next-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToNextSlide(e);
                  }}
                >
                  <ArrowForwardIos sx={{ fontSize: "15px" }} />
                </div>
              </div>
            ))}
          </div>
        </div>
  <div className="list-details">
  <h3 ref={titleRef} className="title">
            {truncatedTitle}
          </h3>
        <p>{city}, {province}</p>
        <p className="blink">Opens:{availabilityPeriod}</p>
        <p className="time-details">
          <span className="time">{openingTime}</span> <span className="to">TO</span> <span className="time">{closingTime}</span>
        </p>
        <p className="placeview">{placeView} Available</p></div>
  
        {/* {!booking ? (
          <>
            
            
          </>
        ) : (
          <>
            <p>
              {startDate} - {endDate}
            </p>
            <p>
              <span>${totalPrice}</span> total
            </p>
          </>
        )} */}
  
        <button
          className="favorite"
          onClick={handleFavoriteClick}
          disabled={!user}
        >
          {isLiked ? (
            <Favorite sx={{ color: "red" }} />
          ) : (
            <Favorite sx={{ color: "white" }} />
          )}
        </button>
      </div>
      </div>
    );
  };
  
  export default RestaurantCard;
  
