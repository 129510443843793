import React from 'react'
import {ArrowForward, Call, Done } from "@mui/icons-material"
import "../styles/About.css"
import { useNavigate } from 'react-router-dom';
export const About = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/about');
    };

  return (
    <div className="abcontainer">
        <div className="about-section">
            <div className="about-text">
                <p>THE    BEST    TRAVEL    AGENCY</p>
                <h1>DISCOVER THE SRI LANKA WITH OUR GUIDE</h1>
                <h4>At Exploring Lanka, we are passionate about sharing the wonders of Sri Lanka with travelers from around the world. Let us guide you on a journey filled with discovery, wonder, and the timeless beauty of this remarkable island.</h4>
                    
                <h4>Ready to start your adventure? Contact us today to begin planning your dream trip to Sri Lanka. We look forward to making your travel dreams a reality with Exploring Lanka.</h4>
                <div className="about-call">
                    <div className="icon-bg">
                        <div className="icon-circle">
                            <Done className='icon'/>
                        </div>
                        <p>5+ Years of Experience</p>
                    </div>
                    <div className="icon-bg">
                        <div className="icon-circle">
                            <Done className='icon'/>
                        </div>
                        <p>150+ Tour Destinations</p>
                    </div>
                </div>
                <div className="about-button" onClick={handleClick}>
                        
                        <h3>Explore more</h3>
                        <ArrowForward className='arrow'/>
                </div>
            </div>

            <div className="about-img">
                <div className="img-before"></div>
                <img src="/assets/about1.jpg" alt="" className='img'/>
                
            </div>
        </div>
    </div>
  )
}
