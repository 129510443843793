import { categories } from "../data";
import "../styles/Categories.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const navigate = useNavigate();

  // Modify getPathVariable to take a label as an argument
  const getPathVariable = (label) => {
    switch (label) {
      case "Hotels":
        return "hotel";
      case "Activities":
        return "activity";
      case "Restaurants":
        return "restaurant";
      case "Vehicle Rent":
        return "vehiclerent";
      default:
        return label.toLowerCase();
    }
  };

  return (
    <div className="categories">
      <h1>Explore Our Services</h1>
      <p>
        Explore our wide range of vacation rentals that cater to all types of
        travelers. Immerse yourself in the local culture, enjoy the comforts of
        home, and create unforgettable memories in your dream destination.
      </p>

      <div className="categories_list">
        {categories?.map((category, index) => {
          const pathVariable = getPathVariable(category.label); // Get path variable for each category
          return (
            <Link to={`/properties/category/${pathVariable}`} key={index}>
              <div className="card">
                <img src={category.img} alt={category.label} className="card-image" />
                <div className="card-overlay"></div>
                <div className="card-content">
                  <span className="card-category">{category.label}</span>
                  <h2>{category.description}</h2>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
