import { useState, useEffect } from "react";
import "../styles/List.css";
import CommonSection from "../shared/commonSection/CommonSection";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setListings } from "../redux/state";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import { vehicleTypes } from "../data";
import {vehifeatures} from "../data";
import { extras } from "../data";

import RestaurantCard from "../components/RestaurantCard";
import RentVehicleCard from "../components/RentVehicleCard";
import { FaFilter } from "react-icons/fa6";
import { BiFilter } from "react-icons/bi";

export const RentVehiclePage = () => {
      const [loading, setLoading] = useState(true);
      const { category } = useParams();
      const [showFilter, setShowFilter] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
      const dispatch = useDispatch();
      const listings = useSelector((state) => Array.isArray(state.listings) ? state.listings : []); // Ensure listings is an array
    
      const [filters, setFilters] = useState({
        place: "",
        maxPrice: 100000,
        type: "",
        seatCount: 0,
        doorCount: 0,
        airConditioning:[],
        driver:[],
        gearType:[],
        vehifeatures:[],
        extras:[],
        mileage:"",  
         minimumPeriod: 0,
        maximumPeriod: 365
      });
    
      const getFeedListings = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/rentvehicle/search/all`, {
            method: "GET",
          });
    
          const data = await response.json();
          console.log(data); // Debug: Check what data is received
          if (Array.isArray(data)) {
            const approvedListings = data.filter(listing => listing.approve === true);
            dispatch(setListings({ listings: approvedListings }));
          } else {
            console.error("Expected an array but got", typeof data);
          }
          setLoading(false);
        } catch (err) {
          console.log("Fetch Listings Failed", err.message);
        }
      };
    
      useEffect(() => {
        getFeedListings();
      }, []);
    
      const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
        }));
      };
    
      // const handlePriceChange = (e) => {
      //   setFilters((prevFilters) => ({
      //     ...prevFilters,
      //     maxPrice: Number(e.target.value),
      //   }));
      // };
    
     

      const handleVehicletypeChange = (e) => {
        const { value } = e.target;
        setFilters((prevFilters) => ({
          ...prevFilters,
          type: prevFilters.type === value ? "" : value,
        }));
      };

      const incrementSeatCount = () => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          seatCount: prevFilters.seatCount + 1,
        }));
      };
    
      const decrementSeatCount = () => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          seatCount: Math.max(prevFilters.seatCount - 1, 0), // Prevent seat count from going below 0
        }));
      };
      const incrementDoorCount = () => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          doorCount: prevFilters.doorCount + 1,
        }));
      };
    
      const decrementDoorCount = () => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          doorCount: Math.max(prevFilters.doorCount - 1, 0),
        }));
      };

      const handleAirConditioningChange = (e) => {
        const { value, checked } = e.target;
    setFilters((prevFilters) => {
      const newairconditioning = checked
        ? [...prevFilters.airConditioning, value]
        : prevFilters.airConditioning.filter((aircondition) => aircondition !== value);
  
      return {
        ...prevFilters,
        airConditioning: newairconditioning,
      };
    });
  };

  const handleDriverChange = (e) => {
    const { value, checked } = e.target;
setFilters((prevFilters) => {
  const newdriver = checked
    ? [...prevFilters.driver, value]
    : prevFilters.driver.filter((drivering) => drivering !== value);

  return {
    ...prevFilters,
    driver: newdriver,
  };
});
};

const handleGeartypeChange = (e) => {
  const { value, checked } = e.target;
setFilters((prevFilters) => {
const newgeartype = checked
  ? [...prevFilters.gearType, value]
  : prevFilters.gearType.filter((gear) => gear !== value);

return {
  ...prevFilters,
  gearType: newgeartype,
};
});
};

const handleVechfacilitiesChange = (e) => {
  const { value, checked } = e.target;
  setFilters((prevFilters) => {
    if (checked) {
      return {
        ...prevFilters,
        vehifeatures: [...prevFilters.vehifeatures, value],
      };
    } else {
      return {
        ...prevFilters,
        vehifeatures: prevFilters.vehifeatures.filter(
          (vehifeature) => vehifeature !== value
        ),
      };
    }
  });
};

const handleVechextrafacilitiesChange = (e) => {
  const { value, checked } = e.target;
  setFilters((prevFilters) => {
    if (checked) {
      return {
        ...prevFilters,
        extras: [...prevFilters.extras, value],
      };
    } else {
      return {
        ...prevFilters,
        extras: prevFilters.extras.filter(
          (extra) => extra !== value
        ),
      };
    }
  });
};

const handlePriceChange = (e) => {
  const { value } = e.target;
  setFilters((prevFilters) => ({
    ...prevFilters,
    maxPrice: Number(value),
  }));
};
const handleMinimumPeriodChange = (e) => {
  const { value } = e.target;
  setFilters((prevFilters) => ({
    ...prevFilters,
    minimumPeriod: Number(value),
  }));
};

const handleMaximumPeriodChange = (e) => {
  const { value } = e.target;
  setFilters((prevFilters) => ({
    ...prevFilters,
    maximumPeriod: Number(value),
  }));
};
      const filteredListings = listings.filter((listing) => {
        
        const placeMatches =
        !filters.place ||
        listing.city.toLowerCase().includes(filters.place.toLowerCase());
        const priceMatches = listing.price <= filters.maxPrice;

        const typeMatches = !filters.type || listing.type === filters.type;
        const seatCountMatches = listing.seatCount >= filters.seatCount;
        const doorCountMatches = listing.doorCount >= filters.doorCount;
        const airConditioningMatches = filters.airConditioning.length === 0 ||
        filters.airConditioning.includes(listing.airConditioning);

        const driverMatches = filters.driver.length === 0 ||
        filters.driver.includes(listing.driver);

        const gearrMatches = filters.gearType.length === 0 ||
        filters.gearType.includes(listing.gearType);

        const vehifeaturesMatches =  filters.vehifeatures.length === 0 ||
        (listing.features && listing.features.some((feature) =>
          filters.vehifeatures.some((vehifeature) =>
            listing.features.some((feature) => {
              const featuresArray = feature.split(",").map((a) => a.trim());
              return featuresArray.includes(vehifeature);
            })
          )
        ));

        const vehiextrafeaturesMatches =  filters.extras.length === 0 ||
        (listing.amenities && listing.amenities.some((amenity) =>
          filters.extras.some((extra) =>
            listing.amenities.some((amenity) => {
              const amenitiesArray = amenity.split(",").map((a) => a.trim());
              return amenitiesArray.includes(extra);
            })
          )
        ));
        const mileageMatches =listing.mileage >= filters.mileage ;

        const minimumPeriodMatches = listing.minimumPeriod >= filters.minimumPeriod;
        const maximumPeriodMatches = listing.maximumPeriod <= filters.maximumPeriod;
  
        return placeMatches&&  typeMatches&& seatCountMatches&& doorCountMatches&& airConditioningMatches&& driverMatches&& gearrMatches&& vehifeaturesMatches&& priceMatches&& vehiextrafeaturesMatches&& mileageMatches&&
        minimumPeriodMatches &&maximumPeriodMatches;
      });
    
    
      return loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div>
            <CommonSection title="Vehicle Rent" />
          </div>
          <div className="content">

          <div className={`filter-box ${showFilter ? 'show' : ''}`}>
              <h3>Filters Your Choices</h3>
              <div className="filter-item">
                <label htmlFor="place">Place</label>
                <input
                  type="text"
                  id="place"
                  name="place"
                  value={filters.place}
                  onChange={handleFilterChange}
                />
              </div>

              <div className="filter-item">
              <label><h3>Vehicle Type</h3></label>
              {vehicleTypes.map((vehicleType) => (
                <div key={vehicleType.name} className="facility-option">
                  <input
                    type="checkbox"e
                    id={vehicleType.name}
                    name="facilities"
                    value={vehicleType.name}
                    onChange={handleVehicletypeChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={vehicleType.name}>{vehicleType.name}</label>
                </div>
              ))}
            </div>

            <div className="filter-item">
          <label><h3>Price Range</h3></label>
          <div className="price-range">
            <input
              type="range"
              min=""
              max="100000"
              value={filters.maxPrice}
              onChange={handlePriceChange}
             
            />
            <span>{filters.maxPrice}</span>
          </div>
        </div>

        <div className="filter-item">
  <label><h3>Mileage Range</h3></label>
  <div className="mileage-range">
    <input
      type="number"
      placeholder="Min Mileage"
      value={filters.mileage}
      onChange={(e) => setFilters({ ...filters, mileage: Number(e.target.value) })}
    />
   
  </div>
</div>

<div className="filter-item">
            <label><h3>Rental Period</h3></label>
            <div className="rental-period">
              <input
                type="number"
                name="minimumPeriod"
                placeholder="Min Days"
                value={filters.minimumPeriod}
                onChange={handleMinimumPeriodChange}
              />
              <input
                type="number"
                name="maximumPeriod"
                placeholder="Max Days"
                value={filters.maximumPeriod}
                onChange={handleMaximumPeriodChange}
              />
            </div>
          </div>


            <div className="filter-item">
            <label htmlFor="seatCount"><h3>Seat Count</h3></label>
            <div className="seat-count-filter">
              <button onClick={decrementSeatCount}>-</button>
              <span>{filters.seatCount}</span>
              <button onClick={incrementSeatCount}>+</button>
            </div>
          </div>


          <div className="filter-item">
            <label htmlFor="doorCount"><h3>Door Count</h3></label>
            <div className="door-count-filter">
              <button onClick={decrementDoorCount}>-</button>
              <span>{filters.doorCount}</span>
              <button onClick={incrementDoorCount}>+</button>
            </div>
          </div>
          <div className="filter-item">
              <label><h3>Basic Features</h3></label>
              {vehifeatures.map((vehifeature) => (
                <div key={vehifeature.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={vehifeature.name}
                    name="facilities"
                    value={vehifeature.name}
                    onChange={handleVechfacilitiesChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={vehifeature.name}>{vehifeature.name}</label>
                </div>
              ))}
            </div>

 <div className="filter-item">
  <label><h3></h3></label>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="air conditioned"
      name="airConditioning"
      value="yes"
      checked={filters.airConditioning.includes("yes")}
      onChange={handleAirConditioningChange}
      className="checkbox-large"
    />
    <label htmlFor="air condition">Air Conditioning</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="non air conditioned"
      name="airConditioning"
      value="no"
      checked={filters.airConditioning.includes("no")}
      onChange={handleAirConditioningChange}
      className="checkbox-large"
    />
    <label htmlFor="air condition">Non Air Conditioning</label>
  </div>
  </div>
  <div className="filter-item">
  <label><h3></h3></label>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="withdriver"
      name="withdriver"
      value="withdriver"
      checked={filters.driver.includes("withdriver")}
      onChange={handleDriverChange}
      className="checkbox-large"
    />
    <label htmlFor="Beach View">With Driver</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="withoutdriver"
      name="withoutdriver"
      value="withoutdriver"
      checked={filters.driver.includes("withoutdriver")}
      onChange={handleDriverChange}
      className="checkbox-large"
    />
    <label htmlFor="Beach View">Without Driver</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="with or withoutdriver"
      name="with or withoutdriver"
      value="with or withoutdriver"
      checked={filters.driver.includes("with or withoutdriver")}
      onChange={handleDriverChange}
      className="checkbox-large"
    />
    <label htmlFor="Beach View">With/Without Driver</label>
  </div></div>

  
  <div className="filter-item">
  <label><h3></h3></label>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="manual"
      name="manual"
      value="manual"
      checked={filters.gearType.includes("manual")}
      onChange={handleGeartypeChange}
      className="checkbox-large"
    />
    <label htmlFor="Beach View">Manual Gear</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="automatic"
      name="automatic"
      value="automatic"
      checked={filters.gearType.includes("automatic")}
      onChange={handleGeartypeChange}
      className="checkbox-large"
    />
    <label htmlFor="Beach View">Automatic Gear</label>
  </div></div>
  <div className="filter-item">
              <label><h3>Extra Features</h3></label>
              {extras.map((extra) => (
                <div key={extra.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={extra.name}
                    name="extras"
                    value={extra.name}
                    onChange={handleVechextrafacilitiesChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={extra.name}>{extra.name}</label>
                </div>
              ))}
            </div>


            </div>
            <div className="list">
              {filteredListings.map(
                ({
                  _id,
                  creator,
                  type,
                streetAddress,
                city,
                province,
                seatCount,
                doorCount,
                features,
                imgUrls,
                model,
                listingPhotoPaths,
                amenities,
                mileage,
                description,
                extraCharge,
                minimumPeriod,
                maximumPeriod,
                contactNumber,
                airConditioning,
                gearType,
                driver,
                price,
                }) => (
                  <RentVehicleCard
                    key={_id}
                    listingId={_id}
                    model={model}
                    seatCount={seatCount}
                    streetAddress={streetAddress}
                    amenities={amenities}
                    mileage={mileage}
                    imgUrls={imgUrls}
                    creator={creator}
                    listingPhotoPaths={listingPhotoPaths}
                    city={city}
                    province={province}
                    category={category}
                    price={price}
                    airConditioning={airConditioning}
                    
                  />
                )
              )}
            </div>

          </div>
          <div><button className="filter-button" onClick={toggleFilter}>
        <BiFilter/>
      </button></div> 
          <Footer />
        </>
      );
    };
    
    export default RentVehiclePage;
    
