import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { facilities } from "../data";
import "../styles/ResLisDetails.css";
import "../styles/ListingDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faPhone } from "@fortawesome/free-solid-svg-icons";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { DateRange } from "react-date-range";
import Loader from "../components/Loader";
// import { useSelector } from "react-redux";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Footer from "../components/Footer";
import Navbar2 from '../components/Navbar2';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useSelector } from "react-redux";

export const RentVehicleDetails = () => {
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false); 
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // Comment section
  const creatorId = useSelector((state) => state.user?._id || '');
  const [formComment, setFormComment] = useState({
    comment: "",
    creator: creatorId,
  });
  const navigate = useNavigate();

  const handleChangeComment = (e) => {
    const { name, value } = e.target;
    setFormComment((prevFormComment) => ({
      ...prevFormComment,
      [name]: value,
    }));
  };

  const getComments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/rentvehicle/${listingId}/comments`, {
        method: "GET",
      });

      const data = await response.json();
      setComments(data);
    } catch (err) {
      console.log("Fetch Comments Failed", err.message);
    }
  };
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    setSubmitting(true); // Show the loader
    try {
      const response = await fetch(`${API_BASE_URL}/rentvehicle/${listingId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formComment),
      });

      if (response.ok) {
        const newComment = await response.json();
        setComments((prevComments) => [...prevComments, newComment]);
        setFormComment({ comment: "", creator: creatorId });
      } else {
        console.error("Failed to submit comment");
      }
    } catch (err) {
      console.error("Submit Comment failed", err.message);
    } finally {
      setSubmitting(false); 
      await getListingDetails(); 
      await getComments(); 
    }
  };

  useEffect(() => {
    getListingDetails();
    getComments();
  }, []);

  

  const getListingDetails = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/rentvehicle/${listingId}`,
        {
          method: "GET",
        }
      );

      const data = await response.json();
      setListing(data);
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listing Details Failed", err.message);
    }
  };

  useEffect(() => {
    getListingDetails();
  }, []);

  const generateMapUrl = (address, city, province) => {
    const query = encodeURIComponent(`${address}, ${city}, ${province}`);
    return `https://www.google.com/maps/search/?api=1&query=${query}`;
  };
  const handleGalleryClick = (index) => {
    setPhotoIndex(index);
    setShowGallery(true);
  };

  const handleDeleteListing = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/rentvehicle/${listingId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        navigate("/properties/category/activity");
      } else {
        console.error("Failed to delete listing");
      }
    } catch (err) {
      console.error("Delete Listing failed", err.message);
    }
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = listing.contactNumber;
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };


  return loading || submitting ? (
    <Loader />
  ) : (
    <>
      <Navbar2 />
      
      <div className="listing-details">
        
      <div className="photos">
                    {/* Main photo */}
                    <img
                        src={listing.imgUrls[0]}
                        alt="listing photo"
                        className="main-photo"
                        onClick={() => handleGalleryClick(0)}// Open gallery when main photo is clicked
                    />
                    {/* Sub photos */}
                    <div className="sub-photos">
            {listing.imgUrls.slice(1, 5).map((item, index) => (
              <div key={index} className="sub-photo-wrapper" onClick={() => handleGalleryClick(index + 1)}>
                <img
                  src={item}
                  alt="listing photo"
                  className="sub-photo"
                />
                {index === 3 && listing.imgUrls.length > 5 && (
                  <div className="more-photos-text">
                    +{listing.imgUrls.length - 5} more
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {showGallery && (
  <Lightbox className="lightbox"
    mainSrc={listing.imgUrls[photoIndex] ? `${listing.imgUrls[photoIndex]}` : ''}
    nextSrc={`${listing.imgUrls[(photoIndex + 1) % listing.imgUrls.length]}`}
    prevSrc={`${listing.imgUrls[(photoIndex + listing.imgUrls.length - 1) % listing.imgUrls.length]
    }`}
    onCloseRequest={() => setShowGallery(false)}
    onMovePrevRequest={() => setPhotoIndex((photoIndex + listing.imgUrls.length - 1) % listing.imgUrls.length)}
    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % listing.imgUrls.length)}
    reactModalStyle={{ overlay: { zIndex: 9999 } }} // Adjust the z-index if necessary
  // Adjust the height of the Lightbox
  />
)}
        <div className="hotel-info-box">
          <h2>{listing.model}</h2>
          <div className="blink">
          <p className="address">(It has {listing.seatCount}Seats & {listing.doorCount} Doors)</p></div>
         
        <p className="address"><a  className="map-link" href={generateMapUrl(listing.streetAddress, listing.city, listing.province)} target="_blank" rel="noopener noreferrer">
            {listing.streetAddress}, {listing.city}, {listing.province} Province - <span className="see-map">SEE MAP</span></a></p>
            <p className="address">
          Hosted by {listing.creator.firstName} {listing.creator.lastName}
        </p>
        <div></div>
        <div className="callview">
        <h2 className="address"><FontAwesomeIcon icon={faPhone} /> : +{listing.contactNumber}</h2></div><hr/>
        <p className="address">{listing.description}</p>
        </div>
        <div className="offers-hotel">
          <div className="rent-offer">
            <div className="rentings">
          {listing.airConditioning === "yes" ? (
            <p>Air conditioning available</p>
          ) : (
            <p>Air conditioning not available</p>
          )}</div>
<div className="rentings">
{listing.gearType === "manual" ? (
            <p>Manual Gear Type available</p>
          ) : (
            <p>Automatic Gear Type available</p>
          )}</div>
          <div className="rentings">
{listing.driver === "withdriver" && <p>With driver available</p>}
          {listing.driver === "withoutdriver" && <p>Without driver available</p>}
          {listing.driver === "withorwithout driver" && <p>With or without driver available</p>}</div>


        </div></div>

        
        <div className="offers-hotel">
        <h3>Main Features Of Our Vehicle</h3>
        <div className="amenities">
            {listing.features[0].split(",").map((item, index) => (
              <div className="facility" key={index}>
                <div className="facility_icon">
                  <FontAwesomeIcon icon={faCheck} />
                  {facilities.find((facility) => facility.name === item)?.icon}
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div><hr/>
          
          <h3>Special Features Of Our Vehicle</h3>
          <div className="amenities">
            {listing.amenities[0].split(",").map((item, index) => (
              <div className="facility" key={index}>
                <div className="facility_icon">
                  <FontAwesomeIcon icon={faCheck} />
                  {facilities.find((facility) => facility.name === item)?.icon}
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="room-details">
    <h3>Rent Details</h3>
    <div className="room-details-table-wrapper">
        <table className="room-details-table">
            <thead>
                <tr>
                    <th>Rate</th>
                    <th>Daily Mileage Limit</th>
                    <th>Extra Mileage Charge (per km)</th>
                    <th>Minimum rental period</th>
                    <th>Maximum rental period</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Rs.{listing.price}</td>
                    <td>{listing.mileage}km</td>
                    <td>Rs.{listing.extraCharge}</td>
                    <td>{listing.minimumPeriod} Day(s)</td>
                    <td>{listing.maximumPeriod} Day(s)</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div className="room-details-boxes">
        <div className="room-detail-box">
            <strong>Rate:</strong> Rs.{listing.price}
        </div>
        <div className="room-detail-box">
            <strong>Daily Mileage Limit:</strong> {listing.mileage}km
        </div>
        <div className="room-detail-box">
            <strong>Extra Mileage Charge (per km):</strong> Rs.{listing.extraCharge}
        </div>
        <div className="room-detail-box">
            <strong>Minimum rental period:</strong> {listing.minimumPeriod} Day(s)
        </div>
        <div className="room-detail-box">
            <strong>Maximum rental period:</strong> {listing.maximumPeriod} Day(s)
        </div>
    </div>
</div>
<hr />
        <div className="comments-section">
          <h2>Public Comments</h2>
          {comments.length === 0 ? (
            <p style={{color:"grey"}}>No comments yet</p>
          ) 
          : (
            comments.map((comment, index) => (
              <div key={index} className="comment-box">
                <div className="comment">
                  <div className="commant-profile">
                    {comment.creator.profileImagePath ? (
                      <img
                        src={`http://localhost:8000/${comment.creator.profileImagePath.replace(
                          "public",
                          ""
                        )}`}
                        alt="profile"
                      />
                    ) : (
                      <img
                        src="http://localhost:8000/default-profile.png"
                        alt="default profile"
                      />
                    )}
                    <h4>by: {comment.creator.firstName} {comment.creator.lastName}</h4>
                  </div>
                  <h3>{comment.comment}</h3>
                  <h5>{comment.createdAt}</h5>
                </div>
              </div>
            ))
          )
          }
        </div>
        <hr />
                  {creatorId ? (
            creatorId === listing.creator._id ? (
              <div className="creator-actions">
                <button className="delete_btn" onClick={handleDeleteListing}>
                  Delete Your Hotel
                </button>
              </div>
            ) : (
              <div className="review-box">
                <h2>Write Your Comments</h2>
                <form onSubmit={handleSubmitComment}>
                  <textarea
                    type="text"
                    placeholder="Give Your Reviews"
                    name="comment"
                    value={formComment.comment}
                    onChange={handleChangeComment}
                  />
                  <button className="comment_btn" type="submit">
                    Submit Your Comment
                  </button>
                </form>
              </div>
            )
          ) : (
            <div className="review-box">
              <h4>Sign in to write Your comment</h4>
              {/* Your login component or message for users to sign in */}
            </div>
          )}




      </div>

      <Footer />
      <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </div>
    </>
  );
};

export default RentVehicleDetails;
