
  import React, { useState, useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import axios from 'axios';
  import Navbar from "../components/Navbar";
  import CommonSection from "../shared/commonSection/CommonSection";
  import "../styles/BlogPage.css";
  import { FaTags } from 'react-icons/fa'; 
  import Footer from "../components/Footer";
  
  const BlogPage = () => {
    const [expandedBlogId, setExpandedBlogId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAllResults, setShowAllResults] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [postIndex, setPostIndex] = useState(null); 
    const [tags, setTags] = useState(["Restaurant food", "Travel news", "Modern technology","Activity","Hiking","Jumping","Hotels","Restaurants","Hiking"]); // Initial tags
    const [filteredTags, setFilteredTags] = useState([]); // State to hold filtered tags
    const navigate = useNavigate(); // Hook for navigation
    const [email, setEmail] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(3); // 
  
    const [blogs, setBlogs] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    useEffect(() => {
      const fetchBlogs = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/blogs/blog-all`);
          if (response.ok) {
            const data = await response.json();
            setBlogs(data);
            console.log('Fetched blogs:', data);
          } else {
            console.error("Failed to fetch blogs");
          }
        } catch (err) {
          console.error("Error fetching blogs:", err.message);
        }
      };
     
  
      fetchBlogs();
    }, []);
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
      setShowAllResults(false);
      setSelectedCategory(null);
    };
  
    const handleSearchButtonClick = () => {
      setShowAllResults(true);
      setSelectedCategory(null);
    };
    
  
    const toggleReadMore = (id) => {
      setExpandedBlogId(expandedBlogId === id ? null : id);
    };
  
    const truncateContent = (content, maxLength) => {
      if (!content) {
        return '';
      }
      if (content.length > maxLength) {
        return content.substring(0, maxLength) + '...';
      }
      return content;
    };
  
    const highlightedText = (text, highlighted) => {
      if (!highlighted.trim()) {
        return text;
      }
      const regex = new RegExp(`(${highlighted})`, 'gi');
      const parts = text.split(regex);
      return parts.map((part, index) => (
        part.toLowerCase() === highlighted.toLowerCase() ? <span key={index} className="highlighted">{part}</span> : part
      ));
    };
  
    const allCategories = [...new Set(blogs.flatMap(blog => blog.categories))];
  
    const filteredBlogs = blogs.filter(blog =>
      blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      blog.Content.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    const filterByCategory = (category) => {
      setSelectedCategory(category);
      setShowAllResults(true);
    };
  
    const getCategoryPostCount = (category) => {
      return blogs.filter(blog => blog.categories.includes(category)).length;
    };
  
    const blogsToShow = selectedCategory
      ? blogs.filter(blog => blog.categories.includes(selectedCategory))
      : (showAllResults ? filteredBlogs : blogs);
  
    const recentPosts = blogs.slice(-5).reverse();
  
    const handlePostClick = (post, index) => {
      setSelectedPost(post);
      setPostIndex(index);
      navigate(`/post/${post.id}`); // Navigate to the selected post page
    };
    const handleTagClick = (tag) => {
      setSelectedCategory(tag); 
      setFilteredTags([tag]); 
    };
    
    const subscribeNewsletter = () => {
      // Implement your subscribe newsletter logic here
      console.log('Subscribed with email:', email);
      // Clear the email input field after subscribing
      setEmail('');
    };
    
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogsToShow.slice(indexOfFirstBlog, indexOfLastBlog);
  
    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(blogsToShow.length / blogsPerPage); i++) {
      pageNumbers.push(i);
    }
    
  
    return (
      <>
        <Navbar />
        <div>
          <CommonSection title="Blogs" />
        </div>
        <section className="blog-page">
          {selectedPost ? (
            <div className="selected-post">
              <h1>{selectedPost.title}</h1>
              <img src={selectedPost.imgUrl} alt={selectedPost.title} />
              
              <p className="date">Published on {selectedPost.date}</p>
              <p className="category"> <FaTags style={{ marginRight: '5px', marginTop:'5px' }} /> {selectedPost.categories.join(', ')}</p>
              <p>{selectedPost.description}</p>
            </div>
          ) : (
            <>
              <div className="blogs-column">
              {currentBlogs.length > 0 ? (
                  currentBlogs.map(blog => (
                    <div className="blog-post" key={blog._id}>
                      <div>
                      <img src={blog.imgUrl} alt={blog.title} />
                      </div>
                      <div className="blog-details">
                        <h2>{highlightedText(blog.title, searchQuery)}</h2>
                        <p className="date">Published on {blog.date}</p>
                        <p className="category"><FaTags style={{ marginRight: '5px' }} /> 
                        {Array.isArray(blog.tags) ? blog.tags.join(', ') : ''}</p>
                        <p>
                          {expandedBlogId === blog.id
                            ? highlightedText(blog.description, searchQuery)
                            : highlightedText(truncateContent(blog.description, 100), searchQuery)}
                        </p>
                        <div className="read-more">
                          <button onClick={() => toggleReadMore(blog.id)} className="readmore">
                            {expandedBlogId === blog.id ? 'Show Less' : 'Read More'}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="no-results">No results found</p>
                )}
               <div className="pagination">
            {currentPage > 1 && (
              <button onClick={() => paginate(currentPage - 1)}>Prev</button>
            )}
            {pageNumbers.slice(currentPage - 1, currentPage + 3).map((number) => (
              <button key={number} onClick={() => paginate(number)}>
                {number}
              </button>
            ))}
            {currentPage < pageNumbers.length && (
              <button onClick={() => paginate(currentPage + 1)}>Next</button>
            )}
          </div>
              </div>
              <div className="search-section">
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder="Search Keyword"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <div className="search-button">
                    <button onClick={handleSearchButtonClick} className="searchbutton">Search</button>
                  </div>
                </div>
                <div className="category-section">
                  <h3>Category</h3>
                  <ul>
                    {allCategories.map((category, index) => (
                      <React.Fragment key={index}>
                        <li className="category-item" onClick={() => filterByCategory(category)}>
                          {category} ({getCategoryPostCount(category)})
                        </li>
                        {index < allCategories.length - 1 && <hr />}
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
                <div className="recent-posts-section">
                  <h3>Recent Posts</h3>
                  <ul>
                    {recentPosts.map((post, index) => (
                      <li key={index} className="recent-post-item" onClick={() => handlePostClick(post)}>
                        
                          
                          <img
                            src={post.imgUrl}
                            alt="recent blog photo"
                            className="recent-post-image"
                          />
                        
                        <div className="recent-post-details">
                          <h4>{post.title.length > 30 ? `${post.title.substring(0, 30)}...` : post.title}</h4>
                          <p className="date">Published on {post.date}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="tag-cloud-section">
            <h3>Tag Clouds</h3>
            <div className="tag-cloud">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className={`tag ${filteredTags.includes(tag) ? 'highlighted' : ''}`}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <div className="newsletter-section">
    <h3> Newsletter</h3>
    <p>Stay updated with our latest articles and news by subscribing to our newsletter.</p>
    <div className="newsletter-form">
      <div className="email-input">
        <input
          type="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="subscribe-button">
        <button onClick={subscribeNewsletter}>Subscribe</button>
      </div>
    </div>
  </div>
  
  
                
              </div>
              
            </>
          )}
        </section>
        <Footer />
      </>
    );
  };
  
  export default BlogPage;
