import "../styles/CreateRestaurant.css";
import { resFacilities, foods, dinings } from "../data";
import { FaMapMarkerAlt, FaUtensils, FaClipboardList } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import { BiTrash } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer"
import resimg from "../images/restaurant.png"
import app from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import Navbar2 from "../components/Navbar2";
import Swal from "sweetalert2";

export const CreateRestaurant = () => {
    const { listingId } = useParams(); // Get the listingId from params
//   const [type, setType] = useState("");
  const [category] = useState("Restaurant");
  const [formLocation, setFormLocation] = useState({
    streetAddress: "",
    city: "",
    province: "",
  });
  const [placeView, setPlaceView] = useState("");
  const [openingTime, setOpeningTime] = useState("");
const [closingTime, setClosingTime] = useState("");
const [availabilityPeriod, setAvailabilityPeriod] = useState("");
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setFormLocation({
      ...formLocation,
      [name]: value,
    });
  };

  const [amenities, setAmenities] = useState([]);
  const [cuisines , setCuisines] = useState([]);
  const [styDinings , setStyDinings] = useState([]);
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]); // New state to keep track of uploaded images
  const [imgPerc, setImgPerc] = useState("");
  const [inputs, setInputs] = useState({});
  const [imageUploaded, setImageUploaded] = useState(false);

  const [menuImages, setMenuImages] = useState([]); 
  const [uploadedMenuImages, setUploadedMenuImages] = useState([]);

  const [contactNumber, setContactNumber] = useState("+94");

  const handleChangeContactNumber = (e) => {
    const { value } = e.target;
    if (value.startsWith("+94")) {
        setContactNumber(value);
    } else if (value === "" || value === "+") {
        setContactNumber("+94");
    }
  };

  const handleSelectAmenities = (facility) => {
    if (amenities.includes(facility)) {
      setAmenities((prevAmenities) =>
        prevAmenities.filter((option) => option !== facility)
      );
    } else {
      setAmenities((prev) => [...prev, facility]);
    }
  };

  const handleSelectDinings = (dining) => {
    if (styDinings.includes(dining)) {
        setStyDinings((prevStyDinings) =>
            prevStyDinings.filter((option) => option !== dining)
      );
    } else {
        setStyDinings((prev) => [...prev, dining]);
    }
  };

  const handleSelectCuisines = (food) => {
    if (cuisines.includes(food)) {
        setCuisines((prevFoods) =>
        prevFoods.filter((option) => option !== food)
      );
    } else {
        setCuisines((prev) => [...prev, food]);
    }
  };



  const [formDescription, setFormDescription] = useState({
    hoteltitle:"",
    title: "",
    description: "",
    highlight: "",
    highlightDesc: "",
    // price: 0,
  });
 

  

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;
    setFormDescription({
      ...formDescription,
      [name]: value,
    });
  };

  const creatorId = useSelector((state) => state.user._id);
  const navigate = useNavigate();

  // Fetch the listing details if listingId exists
  useEffect(() => {
    if (listingId) {
      const fetchListingDetails = async () => {
        try {
          const response = await fetch(`http://localhost:8000/restaurants/${listingId}`, {
            method: "GET",
          });
          const data = await response.json();
          // Populate form fields with the fetched data
        //   setType(data.type);
          setFormLocation({
            streetAddress: data.streetAddress,
            city: data.city,
            province: data.province,
          });
          setAmenities(data.amenities.split(","));
          setCuisines(data.cuisines);
          setStyDinings(data.styDinings);
          setImages(data.imgUrls); // Assuming data.imgUrls is an array of image URLs
      setMenuImages(data.menuImgUrls); // Assuming data.menuImgUrls is an array of menu image URLs
      setInputs({
        imgUrls: data.imgUrls,
        menuImgUrls: data.menuImgUrls,
      });
          setFormDescription({
            title: data.title,
            description: data.description,
            highlight: data.highlight,
            highlightDesc: data.highlightDesc,
            // price: data.price,
          });
          
         
          setPlaceView(data.placeView);
          setOpeningTime(data.openingTime);
          setClosingTime(data.closingTime);
          setAvailabilityPeriod(data.availabilityPeriod);
          
          
        } catch (err) {
          console.error("Fetch Listing Details Failed", err.message);
        }
      };
      fetchListingDetails();
    }
  }, [listingId]);

  // Use a ref to keep track of the files being uploaded to avoid re-uploading the same files
  const imagesRef = useRef([]);
  const menuImagesRef = useRef([]);

  useEffect(() => {
    if (images.length > 0) {
      const newImages = images.filter(image => !imagesRef.current.includes(image));
      newImages.forEach(image => {
        imagesRef.current.push(image);
        uploadFile(image);
      });
    }
  }, [images]);

  useEffect(() => {
    if (menuImages.length > 0) {
      const newMenuImages = menuImages.filter(menuImage => !menuImagesRef.current.includes(menuImage));
      newMenuImages.forEach(menuImage => {
        menuImagesRef.current.push(menuImage);
        uploadMenuFile(menuImage);
      });
    }
  }, [menuImages]);

  const uploadFile = (file) => {
    const storage = getStorage();
    const folder = "images/";
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImgPerc(Math.round(progress));
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        switch (error.code) {
          case 'storage/unauthorized':
            console.log(error);
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            const updatedImgUrls = prev.imgUrls ? [...prev.imgUrls, downloadURL] : [downloadURL];
            return {
              ...prev,
              imgUrls: updatedImgUrls,
            };
          });
          setUploadedImages((prev) => [...prev, downloadURL]);
        });
      }
    );
  };

  const uploadMenuFile = (file) => {
    const storage = getStorage();
    const folder = "menuImages/";
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImgPerc(Math.round(progress));
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        switch (error.code) {
          case 'storage/unauthorized':
            console.log(error);
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            const updatedMenuImgUrls = prev.menuImgUrls ? [...prev.menuImgUrls, downloadURL] : [downloadURL];
            return {
              ...prev,
              menuImgUrls: updatedMenuImgUrls,
            };
          });
          setUploadedMenuImages((prev) => [...prev, downloadURL]);
        });
      }
    );
  };

  const handlePost = async (e) => {
    e.preventDefault();
  
    try {
      const listingForm = new FormData();
      listingForm.append("creator", creatorId);
      listingForm.append("category", category);
      listingForm.append("streetAddress", formLocation.streetAddress);
      listingForm.append("city", formLocation.city);
      listingForm.append("province", formLocation.province);
      listingForm.append("amenities", amenities);
      listingForm.append("cuisines", cuisines);
      listingForm.append("styDinings", styDinings);
      listingForm.append("title", formDescription.title);
      listingForm.append("description", formDescription.description);
      listingForm.append("highlight", formDescription.highlight);
      listingForm.append("highlightDesc", formDescription.highlightDesc);
      listingForm.append("placeView", placeView);
      listingForm.append("openingTime", openingTime);
      listingForm.append("closingTime", closingTime);
      listingForm.append("availabilityPeriod", availabilityPeriod);
      listingForm.append("approve", false);
      listingForm.append("contactNumber", contactNumber);
      inputs.imgUrls.forEach((url, index) => {
        listingForm.append(`imgUrls[${index}]`, url);
      });

      inputs.menuImgUrls.forEach((url, index) => {
        listingForm.append(`menuImgUrls[${index}]`, url);
      });
      
      
  
      for (let [key, value] of listingForm.entries()) {
        console.log(key, value);
      }
  
      const response = await fetch(
        listingId ? `${API_BASE_URL}/restaurants/${listingId}` : `${API_BASE_URL}/restaurants/create/restaurant`,
        {
          method: listingId ? "PUT" : "POST",
          body: listingForm,
        }
      );
  
      if (response.ok) {
        Swal.fire({
          title: "Your Property has been created Successfully! It is under Review for approval",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            popup: 'swal-custom'
          }
        }).then(() => {
          navigate("/");
        });
      } else {
        console.error("Failed to create/update listing");
      }
    } catch (err) {
      console.error("Publish Listing failed", err.message);
    }
  };

  const handleRemoveImage = (index) => {
    if (!inputs.imgUrls || !inputs.imgUrls[index]) {
      console.error("Image URL not found for index:", index);
      return;
    }
    const storagePath = inputs.imgUrls[index];
    const storage = getStorage();
    const imageRef = ref(storage, storagePath);

    deleteObject(imageRef).then(() => {
      console.log('File deleted successfully from Firebase Storage');
      // Update the state to remove the image from the list
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
      setInputs((prev) => {
        const updatedImgUrls = prev.imgUrls.filter((_, i) => i !== index);
        return { ...prev, imgUrls: updatedImgUrls };
      });
      setUploadedImages((prev) => prev.filter((_, i) => i !== index));
      imagesRef.current = imagesRef.current.filter((_, i) => i !== index);
      if (images.length === 1) setImageUploaded(false);
      setImgPerc("");
    }).catch((error) => {
      console.error('Error deleting file from Firebase Storage:', error);
    });
  };

  const handleRemoveMenuImage = (index) => {
    if (!inputs.menuImgUrls || !inputs.menuImgUrls[index]) {
      console.error("Image URL not found for index:", index);
      return;
    }
    const storagePath = inputs.menuImgUrls[index];
    const storage = getStorage();
    const imageRef = ref(storage, storagePath);

    deleteObject(imageRef).then(() => {
      console.log('File deleted successfully from Firebase Storage');
      // Update the state to remove the image from the list
      setMenuImages((prevImages) => prevImages.filter((_, i) => i !== index));
      setInputs((prev) => {
        const updatedImgUrls = prev.menuImgUrls.filter((_, i) => i !== index);
        return { ...prev, menuImgUrls: updatedImgUrls };
      });
      setUploadedMenuImages((prev) => prev.filter((_, i) => i !== index));
      imagesRef.current = imagesRef.current.filter((_, i) => i !== index);
      if (images.length === 1) setImageUploaded(false);
      setImgPerc("");
    }).catch((error) => {
      console.error('Error deleting file from Firebase Storage:', error);
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prev) => [...prev, ...files]);
  };

  const handleMenuFileChange = (e) => {
    const files = Array.from(e.target.files);
    setMenuImages((prev) => [...prev, ...files]);
  };
  
  return (
    <>
        <Navbar2 />
  
        <div className="create-restaurant">
        <div className="heading">

         <div> <h3>PUBLISH YOUR RESTAURANT</h3>
          <p>Showcase your culinary delights to the world. Join our platform to reach more customers and grow your business.<span className="res-highlight">All information is required unless marked optional.</span></p></div>
          <img src={resimg}/>
          </div>
        

          <form onSubmit={handlePost}>
            <div className="create-activities_step1">
            <div className="step-heading">
  <div className="step-number">STEP:01</div>
  <div className="step-description">TELL US ABOUT YOUR RESTAURANT</div>
  
        </div>
            
              <div className="description-box">
            <h3>WHAT MAKES YOUR RESTAURANT SPECIAL & EXCITING?</h3>
            <div className="description">
              <p>Name Of Your Restaurant</p>
            <input
              type="text"
              placeholder="Name of your restaurant"
              name="title"
              value={formDescription.title}
              onChange={handleChangeDescription}
              required
            />
             <div className="description-box-1">
            <p>Description</p>
            <div className="hoteltitle">
            <textarea
              type="text"
              placeholder="Description"
              name="description"
              value={formDescription.description}
              onChange={handleChangeDescription}
              required
            /></div></div>
            <p>Highlight</p>
            <input
              type="text"
              placeholder="Highlight"
              name="highlight"
              value={formDescription.highlight}
              onChange={handleChangeDescription}
              required
            />
            <p>Highlight details</p>
            <textarea
            type="text"
              name="highlightDesc"
              placeholder="Highlight Description"
              value={formDescription.highlightDesc}
              onChange={handleChangeDescription}
              required
            />
          </div></div>

            
          <div className="add-location">
            <h3>WHERE IS YOUR RESTAURANT LOCATED?</h3>
            
            <div className="full">
              <div className="location">
                <p>Street Address</p>
                <input
                  type="text"
                  placeholder="Street Address"
                  name="streetAddress"
                  value={formLocation.streetAddress}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
            </div>
            <div className="half">
              <div className="location">
                <p>Province</p>
                <input
                  type="text"
                  placeholder="Province"
                  name="province"
                  value={formLocation.province}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
              <div className="location">
                <p>City</p>
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formLocation.city}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
            </div>
            </div>
            <div className="add-photos">

            <h3>ADD SOME PHOTOS OF YOUR RESTAURANT</h3>
            <div>
              {images.length > 0 && (
                <div className="uploaded-image-container">
                  {images.map((image, index) => (
                    <div className="uploaded-image" key={index}>
                      {imgPerc > 0 && "Uploading: " + imgPerc + "%"}
                      <img src={URL.createObjectURL(image)} alt="Uploaded" width="100" />
                      <button type="button" onClick={() => handleRemoveImage(index)}>
                        <BiTrash style={{ color: 'red', fontSize: '18px' }} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                id="img"
                multiple
                onChange={handleFileChange}
              />
            </div>
            
            </div>
            <div className="res-view">

            <h3>SELECT YOUR RESTAURANT'S VIEW</h3>
                <div className="place-view">
                  <select 
                    name="placeView" 
                    value={placeView} 
                    onChange={(e) => setPlaceView(e.target.value)} 
                    required
                  >
                    <option value="" disabled>Select your Restaurant view</option>
                    <option value="Lakeview">Lake View</option>
                    <option value="Cityview">City View</option>
                    <option value="Beachview">Beach View</option>
                    <option value="Islandview">Island View</option>
                    <option value="TinyHomes">Tiny Homes</option>
                    <option value="Mountainview">Mountain View</option>
                  </select>
                </div> 
                </div>

                <div className="res-time">
                <h3>SET RESTAURANT OPENING & CLOSING TIMES</h3>
                    <div className="hotel-times">
                    <div className="time-input">
                        <label htmlFor="openingTime">Opening Time</label>
                        <input 
                        type="time" 
                        id="openingTime" 
                        name="openingTime" 
                        value={openingTime} 
                        onChange={(e) => setOpeningTime(e.target.value)} 
                        required 
                        />
                    </div>
                    <div className="time-input">
                        <label htmlFor="closingTime">Closing Time</label>
                        <input 
                        type="time" 
                        id="closingTime" 
                        name="closingTime" 
                        value={closingTime} 
                        onChange={(e) => setClosingTime(e.target.value)} 
                        required 
                        />
                    </div>
                    
                    <div className="time-input">
                    <label htmlFor="availabilityPeriod">Availability Period</label>
                        <select 
                            className="custom-dropdown"
                            name="availabilityPeriod" 
                            value={availabilityPeriod} 
                            onChange={(e) => setAvailabilityPeriod(e.target.value)} 
                            required
                        >
                            <option value="" disabled>Select period</option>
                            <option value="Weekdays">Week Days</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekend">Weekend</option>
                        </select>
                        </div>
                        </div>
            </div></div>


            <div className="create-listing_step2">
            <div className="step-heading">
  <div className="step-number">STEP:02</div>
  <div className="step-description">MAKE YOUR RESTAURANT STANDS OUT</div>
  
</div>
            <hr />
            <div className="dining-style">
            <h3>TELL GUESTS WHAT STYLES OF DINING YOU HAVE</h3>
            <div className="amenities">
              {dinings?.map((item, index) => (
                <div
                  className={`cuisine ${
                    styDinings.includes(item.name) ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectDinings(item.name)}
                >
                  <p>{item.name}</p>
                </div>
              ))}
            
          </div>
          </div>

           <div className="cuisines-style"> <h3>TELL GUESTS WHAT CUISINES YOU HAVE</h3>
            <div className="amenities">
              {foods?.map((item, index) => (
                <div
                  className={`cuisine ${
                    cuisines.includes(item.name) ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectCuisines(item.name)}
                >
                  <p>{item.name}</p>
                </div>
              ))}
            
          </div></div>

          <div className="add-photos">
          <h3>ADD SOME MENU PHOTOS OF YOUR  RESTAURANT</h3>
          <div>
              {menuImages.length > 0 && (
                <div className="uploaded-image-container">
                  {menuImages.map((menuImage, index) => (
                    <div className="uploaded-image" key={index}>
                      {imgPerc > 0 && "Uploading: " + imgPerc + "%"}
                      <img src={URL.createObjectURL(menuImage)} alt="Uploaded" width="100" />
                      <button type="button" onClick={() => handleRemoveMenuImage(index)}>
                        <BiTrash style={{ color: 'red', fontSize: '18px' }} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                id="img"
                multiple
                onChange={handleMenuFileChange}
              />
            </div>
          </div>
          
         <div className="res-offer">  <h3>TELL GUESTS WHAT YOUR PLACE HAS TO OFFER</h3>
            <div className="amenities">
              {resFacilities?.map((item, index) => (
                <div
                  className={`facility ${
                    amenities.includes(item.name) ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectAmenities(item.name)}
                >
                  <div className="facility_icon">{item.icon}</div>
                  <p>{item.name}</p>
                </div>
              ))}
            
          </div>
          </div> 
          <div className="description-box">
        <h3>CONTACT NUMBER</h3>
              <div className="location">
                  <input
                       type="text"
                        placeholder="+94"
                         name="contactNumber"
                         value={contactNumber}
                         onChange={handleChangeContactNumber}
                        required
                          />
             </div></div>    
          </div>
          <button className="submit_btn" type="submit" disabled={images.length < 5}>
            {listingId ? "Update Restaurant" : "PUBLISH YOUR RESTAURANT"}
          </button>
        </form>
      </div>
  
        <Footer />
      </>
  );
};

export default CreateRestaurant;
