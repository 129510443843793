import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { facilities } from "../data";
import "../styles/ResLisDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faPhone } from "@fortawesome/free-solid-svg-icons";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { DateRange } from "react-date-range";
import Loader from "../components/Loader";
// import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import Navbar2 from '../components/Navbar2';

export const ProductDetails = () => {
    const [loading, setLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);

  const getListingDetails = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/products/${listingId}`,
        {
          method: "GET",
        }
      );

      const data = await response.json();
      setListing(data);
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listing Details Failed", err.message);
    }
  };

  useEffect(() => {
    getListingDetails();
  }, []);

  const generateMapUrl = (address, city, province) => {
    const query = encodeURIComponent(`${address}, ${city}, ${province}`);
    return `https://www.google.com/maps/search/?api=1&query=${query}`;
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <Navbar2 />
      
      <div className="listing-details">
        <div className="pro-title">
          <h1>{listing.title}</h1>
          {/* <div></div> */}
        </div>

        <div className="pro-photos">
          
            <img
              src={listing.imgUrl}
              alt="listing photo"
            />
         
        </div>
        <div className="hotel-info-box">
        <p className="address"><a  className="map-link" href={generateMapUrl(listing.streetAddress, listing.city, listing.province)} target="_blank" rel="noopener noreferrer">
            {listing.streetAddress}, {listing.city}, {listing.province} Province - <span className="see-map">SEE MAP</span></a></p>
            <p className="address">
         
        </p>
        <p className="address"> Only Rs.{listing.price}</p>
        <div className="callview">
        <h2 className="address"><FontAwesomeIcon icon={faPhone} /> : +{listing.contactNumber}</h2></div><hr/>
        <p className="address">{listing.description}</p>
        </div>
        
        

        </div>

        

        
         


         
        
      <Footer />
    </>
  );
};

export default ProductDetails;
