import React from 'react'
import "../styles/Product.css"
import {ArrowForward } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

export const Product = () => {
  const navigate = useNavigate();

  const handleClick = () => {
      navigate('/product');
  };
  return (
    <div className="prcontainer">
        <div className="product-section">
            
                <h1>DISCOVER THE SRI LANKA'S DELICIOUS FOOD </h1>
                <p>Embark on a Culinary Odyssey through Sri Lanka's Vibrant Markets, Savory Street Food Stalls, and Elegant Dining Venues. Immerse Yourself in a Tapestry of Aromas and Tastes, Where Every Dish Tells a Story of Culture, History, and Passion for Food.</p>
                <div className="product-button"  onClick={handleClick}>
                     
                    <h3>Explore more</h3>
                    <ArrowForward className='arrow'/></div>
                
            
        </div>
    </div>
  )
}
