import "../styles/PropertyList.css";
import { useSelector } from "react-redux";
import ListingCard from "../components/ListingCard";
import Footer from "../components/Footer"
import Navbar2 from "../components/Navbar2";

const WishList = () => {
  const wishList = useSelector((state) => state.user.wishList);

  return (
    <>
      <Navbar2 />
      <div className="property-list">
        <h1>Your Wish List</h1>
      <div className="list">
        {wishList?.map(
          ({
            _id,
            creator,
            listingPhotoPaths,
            city,
            province,
            category,
            type,
            price,
            booking = false,
          }) => (
            <ListingCard
              listingId={_id}
              creator={creator}
              listingPhotoPaths={listingPhotoPaths}
              city={city}
              province={province}
              category={category}
              type={type}
              price={price}
              booking={booking}
            />
          )
        )}
      </div>
      </div>
      <Footer />
    </>
  );
};

export default WishList;
