import React from 'react'
import { Link } from "react-router-dom";
import "../styles/SelectCategory.css";
import { GiCampingTent } from "react-icons/gi";
import Navbar from '../components/Navbar';
import { Hotel, Restaurant, BikeScooter } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const SelectCategoryPage = () => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };
  return (
    <>
    <Navbar/>
    <div className="scp-container">
        <h1>Welcome!!! Exploring Lanka Seller Site</h1>
        <h2>Which of these categories best describes your place?</h2>
        <div className="scp-contents">
            
                        <div className="spc-card" onClick={() => handleClick('/create-listing')}>
                            <h1>Hotel</h1>
                            <div className="scp-card-icon"><Hotel/></div>
                            <h2>Click here!!! Place Your Hotel</h2>
                        </div>   
                        <div className="spc-card" onClick={() => handleClick('/create-restaurant')}>
                            <h1>Restaurant</h1>
                            <div className="scp-card-icon"><Restaurant/></div>
                            <h2>Click here!!! Place Your Restaurant</h2>
                        </div> 
                        <div className="spc-card" onClick={() => handleClick('/create-activities')}>
                            <h1>Activities</h1>
                            <div className="scp-card-icon"><GiCampingTent/></div>
                            <h2>Click here!!! Place Your Activities</h2>
                        </div> 
                        <div className="spc-card" onClick={() => handleClick('/create-vehiclerent')}>
                            <h1>Vehicle Rent</h1>
                            <div className="scp-card-icon"><BikeScooter/></div>
                            <h2>Click here!!! Place Your Rent Vehicle</h2>
                        </div>         
               
            
        </div>
    </div>
    </>
  )
}
