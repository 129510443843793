import { useState, useEffect } from "react";
import "../styles/List.css";
import CommonSection from "../shared/commonSection/CommonSection";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import Footer from "../components/Footer";

import ProductCard from './../components/ProductCard';
import { BiFilter } from "react-icons/bi";
export const ProductPage = () => {
  const [loading, setLoading] = useState(true);

  const [listings, setListings] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const [filters, setFilters] = useState({
    place: "",
    maxPrice: 100000,
    type: "",
  });
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const getFeedListings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/products/all`, {
        method: "GET",
      });

      const data = await response.json();
      setListings(data);
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listings Failed", err.message);
    }
  };
  useEffect(() => {
    getFeedListings();
  }, []);

 

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };


  const filteredListings = listings.filter((listing) => {
    
    const placeMatches =
    !filters.place ||
    listing.city.toLowerCase().includes(filters.place.toLowerCase());
    const priceMatches = listing.price <= filters.maxPrice;
    return placeMatches&& priceMatches;
  
  });
  const handlePriceChange = (e) => {
    const { value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      maxPrice: Number(value),
    }));
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Navbar />
      <div>
        <CommonSection title="Products" />
      </div>
      <div className="content">
        <div className="filter-box">
          <h3>Filters Your Choices</h3>
          <div className="filter-item">
            <label htmlFor="place">Place</label>
            <input
              type="text"
              id="place"
              name="place"
              value={filters.place}
              onChange={handleFilterChange}
            />
          </div>
          
          <div className="filter-item">
          <label><h3>Price Range</h3></label>
          <div className="price-range">
            <input
              type="range"
              min=""
              max="100000"
              value={filters.maxPrice}
              onChange={handlePriceChange}
             
            />
            <span>{filters.maxPrice}</span>
          </div>
        </div>
        </div>
        <div className="list">
          {filteredListings.map(
            ({
              _id,
              imgUrl,
              listingPhotoPaths,
              title,
              streetAddress,
              city,
              province,
              price,
              contactNumber,
              description,
            }) => (
              <ProductCard
                key={_id}
                listingId={_id}
                title={title}
                imgUrl={imgUrl}
                streetAddress={streetAddress}
                listingPhotoPaths={listingPhotoPaths}
                city={city}
                province={province}
                price={price}
                contactNumber={contactNumber}
                description={description}
              />
            )
          )}
        </div>
      </div>
      <div><button className="filter-button" onClick={toggleFilter}>
        <BiFilter/>
      </button></div> 
      <Footer />
    </>
  );
};

export default ProductPage;

