import { useState ,useEffect, useRef} from "react";
import "../styles/ListingCard.css";

import { useNavigate } from "react-router-dom";



const ProductCard = ({
    listingId,
    imgUrl,
    listingPhotoPaths,
    title,
    streetAddress,
    city,
    province,
    price,
    contactNumber,
    description,
}) => {
    /* SLIDER FOR IMAGES */

  
    const navigate = useNavigate();
  
    /* ADD TO WISHLIST */
    // const user = useSelector((state) => state.user);
    // const wishList = user?.wishList || [];
  
    // const isLiked = wishList?.find((item) => item?._id === listingId);
  
    // const patchWishList = async () => {
    //   if (user?._id !== creator._id) {
    //   const response = await fetch(
    //     `http://localhost:8000/users/${user?._id}/${listingId}`,
    //     {
    //       method: "PATCH",
    //       header: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   const data = await response.json();
    //   dispatch(setWishList(data.wishList));
    // } else { return }
    // };
    const titleRef = useRef(null);
    const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
    useEffect(() => {
      const checkTitleOverflow = () => {
        if (titleRef.current) {
          const isOverflowing =
            titleRef.current.scrollWidth > titleRef.current.clientWidth;
          setIsTitleOverflowing(isOverflowing);
        }
      };
  
      window.addEventListener("resize", checkTitleOverflow);
      checkTitleOverflow(); // Initial check
      return () => {
        window.removeEventListener("resize", checkTitleOverflow);
      };
    }, []);
  
    const truncatedTitle = isTitleOverflowing
    ? title.slice(0, 15) + "..." // Adjust the number of characters as needed
    : title;
  
  const handleClick = () => {
    navigate(`/restaurants/detail/${listingId}`);
  };
  
    return (
     
      <div
        className="pro-listing-card"
        onClick={() => {
          navigate(`/products/detail/${listingId}`);
        }}
      >
        <img src={imgUrl} alt={`img`}/>
        <div className="pro-list">
        <h3 ref={titleRef} className="title">
            {truncatedTitle}
          </h3>
        <p>{streetAddress}</p>
        <p>{city}, {province}</p>
        
        <p className="blink">Rs.{price}</p>
        <p className="placeview">View Details</p>

        </div>

  
    
      </div>
      
    );
  };
  
  export default ProductCard;
  