// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyClCxdQcia3xhi14rTtob2Y0t4LkyJXoCQ",
  authDomain: "exploring-lanka-files.firebaseapp.com",
  projectId: "exploring-lanka-files",
  storageBucket: "exploring-lanka-files.appspot.com",
  messagingSenderId: "1071543701905",
  appId: "1:1071543701905:web:1ca62f888ad65e87f68a30"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;