import { useState, useEffect } from "react";
import "../styles/List.css";
import CommonSection from "../shared/commonSection/CommonSection";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setListings } from "../redux/state";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import {foods} from "../data";
import { dinings } from "../data";
import { resFacilities } from "../data";
import RestaurantCard from "../components/RestaurantCard";
import "../styles/List.css";

export const RestaurantPage = () => {
  const [loading, setLoading] = useState(true);
  const { category } = useParams();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const listings = useSelector((state) => Array.isArray(state.listings) ? state.listings : []); // Ensure listings is an array

  const [filters, setFilters] = useState({
    place: "",
    placeView: [],
    foods: [],
    dinings:[],
    availabilityPeriod: "",
    openingTime: "",
    closingTime: "",
    resFacilities:[]
  });

  const getFeedListings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/restaurants/search/all`, {
        method: "GET",
      });

      const data = await response.json();
      console.log(data); // Debug: Check what data is received
      if (Array.isArray(data)) {
        const approvedListings = data.filter(listing => listing.approve === true);
        dispatch(setListings({ listings: approvedListings }));
      } else {
        console.error("Expected an array but got", typeof data);
      }
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listings Failed", err.message);
    }
  };

  useEffect(() => {
    getFeedListings();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
 
  
  // const handlePriceChange = (e) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     maxPrice: Number(e.target.value),
  //   }));
  // };

  const handleTypeChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      type: prevFilters.type === e.target.value ? "" : e.target.value,
    }));
  };

  const handlePlaceViewChange = (e) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      const newPlaceView = checked
        ? [...prevFilters.placeView, value]
        : prevFilters.placeView.filter((view) => view !== value);
  
      return {
        ...prevFilters,
        placeView: newPlaceView,
      };
    });
  };

  const handleCuisinesChange = (e) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      if (checked) {
        return {
          ...prevFilters,
          foods: [...prevFilters.foods, value],
        };
      } else {
        return {
          ...prevFilters,
          foods: prevFilters.foods.filter(
            (food) => food !== value
          ),
        };
      }
    });
  };

  const handlediningsChange = (e) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      if (checked) {
        return {
          ...prevFilters,
          dinings: [...prevFilters.dinings, value],
        };
      } else {
        return {
          ...prevFilters,
          dinings: prevFilters.dinings.filter(
            (dining) => dining !== value
          ),
        };
      }
    });
  };
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  
  const handleresFacilitiesChange = (e) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      if (checked) {
        return {
          ...prevFilters,
          facilities: [...prevFilters.resFacilities, value],
        };
      } else {
        return {
          ...prevFilters,
          resFacilities: prevFilters.resFacilities.filter(
            (resFacility) => resFacility !== value
          ),
        };
      }
    });
  };


  const filteredListings = listings.filter((listing) => {
    
      const placeMatches =
      !filters.place ||
      listing.city.toLowerCase().includes(filters.place.toLowerCase());
      const placeViewMatches =
      filters.placeView.length === 0 ||
      filters.placeView.includes(listing.placeView);
      const availabilityPeriodMatches = !filters.availabilityPeriod || listing.availabilityPeriod === filters.availabilityPeriod;
      const cuisinesMatches =
      filters.foods.length === 0 ||
      (listing.cuisines && listing.cuisines .some((cuisine) =>
        filters.foods.some((food) =>
          listing.cuisines .some((cuisine) => {
            const cuisinesArray = cuisine.split(",").map((a) => a.trim());
            return cuisinesArray.includes(food);
          })
        )
      ));

      const diningsMatches =
      filters.dinings.length === 0 ||
      (listing.styDinings && listing.styDinings .some((styDining) =>
        filters.dinings.some((dining) =>
          listing.styDinings .some((styDining) => {
            const styDiningsArray = styDining.split(",").map((a) => a.trim());
            return styDiningsArray.includes(dining);
          })
        )
      ));

      const resfacilitiesMatches =
      filters.resFacilities.length === 0 ||
      (listing.amenities && listing.amenities.some((amenity) =>
        filters.resFacilities.some((resFacility) =>
          listing.amenities.some((amenity) => {
            const amenitiesArray = amenity.split(",").map((a) => a.trim());
            return amenitiesArray.includes(resFacility);
          })
        )
      ));

      const openingTimeMatches = !filters.openingTime || (listing.openingTime && listing.openingTime <= filters.openingTime);
      const closingTimeMatches = !filters.closingTime || (listing.closingTime && listing.closingTime >= filters.closingTime);
      return placeMatches  && placeViewMatches&& availabilityPeriodMatches&& cuisinesMatches&& diningsMatches&& openingTimeMatches && closingTimeMatches&& resfacilitiesMatches;
   
  });


  return loading ? (
    <Loader />
  ) : (
    <>
      <Navbar />
      <div>
        <CommonSection title="Restaurants" />
      </div>
      <div className="content">
        <div className="filter-box">
          <h3>Filters Your Choices</h3>
          <div className="filter-item">
            <label htmlFor="place">Place</label>
            <input
              type="text"
              id="place"
              name="place"
              value={filters.place}
              onChange={handleFilterChange}
            />
          </div>
          {/* <div className="filter-item">
            <label htmlFor="priceRange">Price Range</label>
            <input
              type="range"
              id="priceRange"
              name="priceRange"
              min="0"
              max="100000"
              step="1000"
              value={filters.maxPrice}
              onChange={handlePriceChange}
            />
          </div> */}
              <div className="filter-item">
  <label><h3>Place View</h3></label>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Beach View"
      name="placeView"
      value="beachview"
      checked={filters.placeView.includes("beachview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Beach View">Beach View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Lake View"
      name="placeView"
      value="Lakeview"
      checked={filters.placeView.includes("Lakeview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Lake View">Lake View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="City View"
      name="placeView"
      value="Cityview"
      checked={filters.placeView.includes("Cityview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="City View">City View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Island View"
      name="placeView"
      value="Islandview"
      checked={filters.placeView.includes("Islandview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Island View">Island View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Mountain View"
      name="placeView"
      value="Mountainview"
      checked={filters.placeView.includes("Mountainview")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Mountain View">Mountain View</label>
  </div>
  <div className="place-view-option">
    <input
      type="checkbox"
      id="Tiny Homes"
      name="placeView"
      value="TinyHomes"
      checked={filters.placeView.includes("TinyHomes")}
      onChange={handlePlaceViewChange}
      className="checkbox-large"
    />
    <label htmlFor="Tiny Homes">Tiny Homes</label>
  </div>
</div>
<div className="filter-item">
  <h3>Availability Period</h3>
  <div className="filter-availability-period">
    <input
      type="checkbox"
      name="availabilityPeriod"
      value="Daily"
      checked={filters.availabilityPeriod.includes("Daily")}
      onChange={handleFilterChange}
      className="checkbox-large" // Add class for larger checkbox
    />
    <label>Daily</label>
  </div>
  <div className="filter-availability-period">
    <input
      type="checkbox"
      name="availabilityPeriod"
      value="Weekdays"
      checked={filters.availabilityPeriod.includes("Weekdays")}
      onChange={handleFilterChange}
      className="checkbox-large" // Add class for larger checkbox
    />
    <label>Weekdays</label>
  </div>
  <div className="filter-availability-period">
    <input
      type="checkbox"
      name="availabilityPeriod"
      value="Weekend"
      checked={filters.availabilityPeriod.includes("Weekend")}
      onChange={handleFilterChange}
      className="checkbox-large" // Add class for larger checkbox
    />
    <label>Weekend</label>
  </div>
</div>
<div className="filter-item">
              <label><h3>Cuisines</h3></label>
              {foods.map((food) => (
                <div key={food.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={food.name}
                    name="cuisines"
                    value={food.name}
                    onChange={handleCuisinesChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={food.name}>{food.name}</label>
                </div>
              ))}
            </div>
            <div className="filter-item">
              <label><h3>Facilities</h3></label>
              {resFacilities.map((resFacility) => (
                <div key={resFacility.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={resFacility.name}
                    name="facilities"
                    value={resFacility.name}
                    onChange={handleresFacilitiesChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={resFacility.name}>{resFacility.name}</label>
                </div>
              ))}
            </div>
          
      


            <div className="filter-item">
              <label><h3>Dinings</h3></label>
              {dinings.map((dining) => (
                <div key={dining.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={dining.name}
                    name="styDinings"
                    value={dining.name}
                    onChange={handlediningsChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={dining.name}>{dining.name}</label>
                </div>
              ))}
            </div>
            <div className="filter-item">
  <label htmlFor="openingTime">Opening Time</label>
  <input
    type="time"
    id="openingTime"
    name="openingTime"
    value={filters.openingTime}
    onChange={handleTimeChange}
  />
</div>
<div className="filter-item">
  <label htmlFor="closingTime">Closing Time</label>
  <input
    type="time"
    id="closingTime"
    name="closingTime"
    value={filters.closingTime}
    onChange={handleTimeChange}
  />
</div>



       

     
        </div>
        <div className="list">
          {filteredListings.map(
            ({
              _id,
              creator,
              listingPhotoPaths,
              streetAddress,
              amenities,
              cuisines,
              styDinings,
              city,
              province,
              title,
              category,
              imgUrls,
              menuImgUrls,
              placeView,
              openingTime,
              closingTime,
              availabilityPeriod,
            }) => (
              <RestaurantCard
                key={_id}
                listingId={_id}
                title={title}
                streetAddress={streetAddress}
                amenities={amenities}
                cuisines={cuisines}
                styDinings={styDinings}
                creator={creator}
                listingPhotoPaths={listingPhotoPaths}
                city={city}
                menuImgUrls={menuImgUrls}
                imgUrls={imgUrls}
                province={province}
                category={category}
                placeView={placeView}
                openingTime={openingTime}
                closingTime={closingTime}
                availabilityPeriod={availabilityPeriod}
              />
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RestaurantPage;
