import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FaCheck, FaStar,FaStreetView  } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import Navbar2 from '../components/Navbar2';
import { hotelservices } from "../data";
import "../styles/ListingDetails.css";
import "../styles/ResLisDetails.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

const HotelListingDetails = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // New state for handling comment submission loading
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { listingId } = useParams();
  const [listing, setListing] = useState(null);
  const [comments, setComments] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);


  const navigate = useNavigate();

  // Comment section
  const creatorId = useSelector((state) => state.user?._id || '');
  const [formComment, setFormComment] = useState({
    comment: "",
    creator: creatorId,
  });

  const handleChangeComment = (e) => {
    const { name, value } = e.target;
    setFormComment((prevFormComment) => ({
      ...prevFormComment,
      [name]: value,
    }));
  };

  const getListingDetails = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/properties/${listingId}`,
        {
          method: "GET",
        }
      );

      const data = await response.json();
      setListing(data);
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listing Details Failed", err.message);
    }
  };

  const getComments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/properties/${listingId}/comments`, {
        method: "GET",
      });

      const data = await response.json();
      setComments(data);
    } catch (err) {
      console.log("Fetch Comments Failed", err.message);
    }
  };
  
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    setSubmitting(true); // Show the loader
    try {
      const response = await fetch(`${API_BASE_URL}/properties/${listingId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formComment),
      });

      if (response.ok) {
        const newComment = await response.json();
        setComments((prevComments) => [...prevComments, newComment]);
        setFormComment({ comment: "", creator: creatorId });
      } else {
        console.error("Failed to submit comment");
      }
    } catch (err) {
      console.error("Submit Comment failed", err.message);
    } finally {
      setSubmitting(false); // Hide the loader
      await getListingDetails(); // Refresh listing details
      await getComments(); // Refresh comments
    }
  };

  useEffect(() => {
    getListingDetails();
    getComments();
  }, []);

  const handleGalleryClick = (index) => {
    setPhotoIndex(index);
    setShowGallery(true);
  };

  const generateMapUrl = (address, city, province) => {
    const query = encodeURIComponent(`${address}, ${city}, ${province}`);
    return `https://www.google.com/maps/search/?api=1&query=${query}`;
  };
  const renderStars = (rating) => {
    return Array.from({ length: rating }, (v, i) => (
      <FaStar key={i} color="gold" />
    ));
  };

  const handleDeleteListing = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/properties/${listingId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        Swal.fire({
          title: "Delete Successful",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            popup: 'swal-custom'
          }
        }).then(() => {
          navigate("/properties/category/hotel");
        });
      } else {
        console.error("Failed to delete listing");
      }
    } catch (err) {
      console.error("Delete Listing failed", err.message);
    }
  };
  const handleWhatsAppClick = () => {
    const phoneNumber = listing.contactNumber;
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };


  return loading || submitting ? (
    <Loader />
  ) : (
    <>
    <Navbar2/>
      
        


      <div className="listing-details">
                <div className="photos">
                    {/* Main photo */}
                    <img
                        src={listing.hotelPhotos[0]}
                        alt="listing photo"
                        className="main-photo"
                        onClick={() => handleGalleryClick(0)}// Open gallery when main photo is clicked
                    />
                    {/* Sub photos */}
                    <div className="sub-photos">
            {listing.hotelPhotos.slice(1, 5).map((item, index) => (
              <div key={index} className="sub-photo-wrapper" onClick={() => handleGalleryClick(index + 1)}>
                <img
                  src={item}
                  alt="listing photo"
                  className="sub-photo"
                />
                {index === 3 && listing.hotelPhotos.length > 5 && (
                  <div className="more-photos-text">
                    +{listing.hotelPhotos.length - 5} more
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="hotel-info-box">
        
        <h2>
          {listing.hoteltitle}  <span className="star-rating">
              {Array.from({ length: listing.starRating }, (v, i) => (
                <FaStar key={i} style={{ color: "rgb(223, 85, 5)", fontSize: "10px" }} />
              ))}
            </span>
        </h2>
        <p className="address"><a  className="map-link" href={generateMapUrl(listing.streetAddress, listing.city, listing.province)} target="_blank" rel="noopener noreferrer">
            {listing.streetAddress}, {listing.city}, {listing.province} Province - <span className="see-map">SEE MAP</span></a></p>
            <p className="address">
          Hosted by {listing.creator.firstName} {listing.creator.lastName}
        </p>
        <div className="callview">
        <h2 className="address"><FontAwesomeIcon icon={faPhone} /> : +{listing.contactNumber}</h2>
        </div>
        <div className="views"> <br/> <p><FaStreetView/>:{listing.placeView} Available</p></div><hr/>
        <p className="address">{listing.hotelDescription}</p></div> 
        {/* <h4>
          {listing.guestCount} guests - {listing.bedroomCount} bedroom(s) -{" "}
          {listing.bedCount} bed(s) - {listing.bathroomCount} bathroom(s) 
        </h4>
        <h2>Rs.{listing.price}</h2> */}
        

        {/* <h3>Description</h3>
        <p>{listing.description}</p>
        <hr />

        <h3>{listing.highlight}</h3>
        <p>{listing.highlightDesc}</p>
        <hr /> */}

<div className="offers-hotel">
          <h3>This place offers</h3>
          <div className="amenities">
            {listing.hotelHighlights[0].split(",").map((item, index) => (
              <div className="facility" key={index}>
                <div className="facility_icon">
                <img src={
                    hotelservices.find((hotelservice) => hotelservice.name === item)
                      ?.image
                  }/>
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
        

 {/* <div className="highlights">
          <h4>Highlights</h4>
          <div className="highlight-list">
            {hotelservices.map((highlight, index) => (
              <div className="highlight" key={index}>
                 <div className="highlight-image">
      <img src={highlight.image} alt={highlight.name} />
    </div>
                <p>{highlight.name}</p>
              </div>
            ))}
          </div> */}
        
        <div className="room-details">
          <h3>Room Details</h3><br/>
          <table className="room-details-table">
            <thead>
              <tr>
                <th>Room Type</th>
                <th>Facilities</th>
                <th>Guest Count</th>
                <th>Bedroom Count</th>
                <th>Bed Count</th>
                <th>Bathroom Count</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {listing.rooms?.map((room, index) => (
                <tr key={index}>
                 <td data-label="Room Type">
                    <div className="room-type">
                      
                        <img
                          src={room.imageUrl}
                          alt={room.title}
                          className="room-image"
                        />
                       
                      <p>{room.title}</p>
                    </div>
                  </td>
                  
                  <td className="thd" data-label="Facilities">
                    <ul>
                      {room.amenities[0].split(",").map((amenity, index) => (
                        <li key={index}>
                          <FaCheck style={{ color: 'green' }} /> {amenity.trim()}
                        </li>
                      ))}
                    </ul>
                    
                  </td>
                  <td data-label="Guest Count">{room.guestCount}</td>
                        <td data-label="Bedroom Count">{room.bedroomCount}</td>
                        <td data-label="Bed Count">{room.bedCount}</td>
                        <td data-label="Bathroom Count">{room.bathroomCount}</td>
                        <td data-label="Price">Rs. {room.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        

     
        <div className="comments-section">
          <h2>Public Comments</h2>
          {comments.length === 0 ? (
            <p style={{color:"grey"}}>No comments yet</p>
          ) : (
            comments.map((comment, index) => (
              <div key={index} className="comment-box">
                <div className="comment">
                  <div className="commant-profile">
                    {comment.creator.profileImagePath ? (
                      <img
                        src={`http://localhost:8000/${comment.creator.profileImagePath.replace(
                          "public",
                          ""
                        )}`}
                        alt="profile"
                      />
                    ) : (
                      <img
                        src="http://localhost:8000/default-profile.png"
                        alt="default profile"
                      />
                    )}
                    <h4>by: {comment.creator.firstName} {comment.creator.lastName}</h4>
                  </div>
                  <h3>{comment.comment}</h3>
                  <h5>{comment.createdAt}</h5>
                </div>
              </div>
            ))
          )}
        </div>
        <hr />
        {showGallery && (
  <Lightbox className="lightbox"
    mainSrc={listing.hotelPhotos[photoIndex] ? `${listing.hotelPhotos[photoIndex]}` : ''}
    nextSrc={`${listing.hotelPhotos[(photoIndex + 1) % listing.hotelPhotos.length]}`}
    prevSrc={`${
      listing.hotelPhotos[(photoIndex + listing.hotelPhotos.length - 1) % listing.hotelPhotos.length]
    }`}
    onCloseRequest={() => setShowGallery(false)}
    onMovePrevRequest={() => setPhotoIndex((photoIndex + listing.hotelPhotos.length - 1) % listing.hotelPhotos.length)}
    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % listing.hotelPhotos.length)}
    reactModalStyle={{ overlay: { zIndex: 9999 } }} // Adjust the z-index if necessary
  // Adjust the height of the Lightbox
  />
)}
        {creatorId ? (
            creatorId === listing.creator._id ? (
              <div className="creator-actions">
                <button className="delete_btn" onClick={handleDeleteListing}>
                  Delete Your Hotel
                </button>
              </div>
            ) : (
              <div className="review-box">
                <h2>Write Your Comments</h2>
                <form onSubmit={handleSubmitComment}>
                  <textarea
                    type="text"
                    placeholder="Give Your Reviews"
                    name="comment"
                    value={formComment.comment}
                    onChange={handleChangeComment}
                  />
                  <button className="comment_btn" type="submit">
                    Submit Your Comment
                  </button>
                </form>
              </div>
            )
          ) : (
            <div className="review-box">
              <h4>Sign in to write Your comment</h4>
              {/* Your login component or message for users to sign in */}
            </div>
          )}
      </div>

      <Footer />
      <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </div>
    </>
  );
};

export default HotelListingDetails;
