import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; // Import the uuid library
import Footer from "../components/Footer";
import Navbar2 from "../components/Navbar2";
import { hotelservices } from "../data";
import { BiTrash } from "react-icons/bi";
import "../styles/CreateHotel.css";
import Swal from "sweetalert2";
import "../styles/CreateRestaurant.css";
import hotimg from "../images/hotel4.png"
import app from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
const CreateListing = () => {
  const location = useLocation();
  const initialLocation = location.state?.streetAddress || "";
  const initialCity = location.state?.city || "";
  const initialProvince = location.state?.province || "";
  const initialZipCode = location.state?.zipCode || "";
  const initialTitle = location.state?.hoteltitle || "";
  const initialDescription = location.state?.hotelDescription || "";
  const initialHighlights = location.state?.hotelHighlights|| [];
  const initialStarRating = location.state?.starRating || "N/A";
  const initialPlaceView = location.state?.placeView || "";
  const initialHotelId = location.state?.hotelId || uuidv4(); // Generate initial ID or use existing ID
  const initialImages = location.state?.uploadedImages || []; 
  
  const [hotelHighlights, sethotelHighlights] = useState(initialHighlights);
  const [category] = useState("Hotel");
  const [formLocation, setFormLocation] = useState({
    streetAddress: initialLocation,
    city: initialCity,
    province: initialProvince,
    zipCode: initialZipCode,
  });
  const [starRating, setStarRating] = useState(initialStarRating);
  const [formDescription, setFormDescription] = useState({
    hoteltitle: initialTitle,
    hotelDescription:initialDescription
  });
  const [placeView, setPlaceView] = useState(initialPlaceView);
  const [hotelId, setHotelId] = useState(initialHotelId); // State to hold hotel ID

  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState(initialImages); // New state to keep track of uploaded images
  const [imgPerc, setImgPerc] = useState("");
  const [inputs, setInputs] = useState({});
  const [imageUploaded, setImageUploaded] = useState(false);

  const [contactNumber, setContactNumber] = useState("+94");

  const handleChangeContactNumber = (e) => {
    const { value } = e.target;
    if (value.startsWith("+94")) {
        setContactNumber(value);
    } else if (value === "" || value === "+") {
        setContactNumber("+94");
    }
  };

  const handleSelectHighlights = (hotelservice) => {
    if (hotelHighlights.includes(hotelservice)) {
      sethotelHighlights((prevHotelHighlights) =>
        prevHotelHighlights.filter((option) => option !== hotelservice)
      );
    } else {
      sethotelHighlights((prev) => [...prev, hotelservice]);
    }
  };

  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setFormLocation({
      ...formLocation,
      [name]: value,
    });
  };

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;
    setFormDescription({
      ...formDescription,
      [name]: value,
    });
  };

  const creator = useSelector((state) => state.user._id);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.hotelId) {
      // If hotel ID exists in location state, set it in the state
      setHotelId(location.state.hotelId);
    }
  }, [location.state]); // Re-run effect when location state changes

  const handlePost = async (e) => {
    e.preventDefault();
    
    navigate("/create-room", {
      state:{
        creator,
        category,
        hotelId, // Pass the hotel ID
        hoteltitle: formDescription.hoteltitle,
        hotelDescription:formDescription.hotelDescription,
        streetAddress: formLocation.streetAddress,
        city:formLocation.city,
        province:formLocation.province,
        zipCode:formLocation.zipCode,
        starRating,
        contactNumber,
        placeView,
        hotelHighlights,
        uploadedImages,
      }
    });
  };
  const imagesRef = useRef([]);

  useEffect(() => {
    if (images.length > 0) {
      const newImages = images.filter(image => !imagesRef.current.includes(image));
      newImages.forEach(image => {
        imagesRef.current.push(image);
        uploadFile(image);
      });
    }
  }, [images]);

  const uploadFile = (file) => {
    const storage = getStorage();
    const folder = "images/";
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImgPerc(Math.round(progress));
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        switch (error.code) {
          case 'storage/unauthorized':
            console.log(error);
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setInputs((prev) => {
            const updatedImgUrls = prev.imgUrls ? [...prev.imgUrls, downloadURL] : [downloadURL];
            return {
              ...prev,
              imgUrls: updatedImgUrls,
            };
          });
          setUploadedImages((prev) => [...prev, downloadURL]);
        });
      }
    );
  };
 
  const handleRemoveImage = (index) => {
    if (!inputs.imgUrls || !inputs.imgUrls[index]) {
      console.error("Image URL not found for index:", index);
      return;
    }
    const storagePath = inputs.imgUrls[index];
    const storage = getStorage();
    const imageRef = ref(storage, storagePath);

    deleteObject(imageRef).then(() => {
      console.log('File deleted successfully from Firebase Storage');
      // Update the state to remove the image from the list
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
      setInputs((prev) => {
        const updatedImgUrls = prev.imgUrls.filter((_, i) => i !== index);
        return { ...prev, imgUrls: updatedImgUrls };
      });
      setUploadedImages((prev) => prev.filter((_, i) => i !== index));
      imagesRef.current = imagesRef.current.filter((_, i) => i !== index);
      if (images.length === 1) setImageUploaded(false);
      setImgPerc("");
    }).catch((error) => {
      console.error('Error deleting file from Firebase Storage:', error);
    });
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...newFiles]);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (images.length < 5) {
      Swal.fire({
        title: 'Error!',
        text: 'You need to add at least 5 photos.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } else {
      // Handle the form submission logic
      console.log('Form submitted');
    }
  };

  return (
    <>
      <Navbar2 />
      <div className="create-restaurant">
        <div className="heading">

         <div> <h3>PUBLISH YOUR HOTEL</h3>
          <p>Showcase your culinary delights to the world. Join our platform to reach more customers and grow your business.<span className="res-highlight">All information is required unless marked optional.</span></p></div>
          <img src={hotimg}/>
          </div>
          {/* <div className="progress-bar">
  <div className="progress" style={{ width: `${progress}%` }}></div>
  <div className="progress-icons">
    <FaMapMarkerAlt className="progress-icon" />
    <FaUtensils className="progress-icon" />
    <FaClipboardList className="progress-icon" />
    
  </div>
</div> */}
        <form onSubmit={handlePost}>
          <div className="create-activities_step1">
          <div className="step-heading">
  <div className="step-number">STEP:01</div>
  <div className="step-description">TELL US ABOUT YOUR HOTEL</div>
  
</div>
<div className="description-box">
            <h3>WHAT MAKES YOUR HOTEL SPECIAL & EXCITING?</h3>
            <div className="description">
              <p>Name Of Your Hotel</p>
            <input
              type="text"
              placeholder="Hotel Name"
              name="hoteltitle"
              value={formDescription.hoteltitle}
              onChange={handleChangeDescription}
              required
            />
            </div>
            </div>

            
            <div className="add-location">
              <h3>WHERE IS YOUR HOTEL LOCATED?</h3>
            <div className="half">
              <div className="location">
                <p>Street Address</p>
                <input
                  type="text"
                  placeholder="Street Address"
                  name="streetAddress"
                  value={formLocation.streetAddress}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
              <div className="location">
                <p>City</p>
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formLocation.city}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
            </div>
            <div className="half">
              <div className="location">
                <p>Province</p>
                <input
                  type="text"
                  placeholder="Province"
                  name="province"
                  value={formLocation.province}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
              <div className="location">
                <p>Zip Code</p>
                <input
                  type="number"
                  placeholder="Zip Code"
                  name="zipCode"
                  value={formLocation.zipCode}
                  onChange={handleChangeLocation}
                  required
                />
              </div>
            </div></div>


            <div className="add-location">
            <h3>RATE YOUR HOTEL</h3>
            <div className="star-rating">
              <label>
                <input
                  type="radio"
                  name="starRating"
                  value="N/A"
                  checked={starRating === "N/A"}
                  onChange={(e) => setStarRating(e.target.value)}
                />
                N/A
              </label>
              <label>
                <input
                  type="radio"
                  name="starRating"
                  value="1"
                  checked={starRating === "1"}
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="star">⭐</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="starRating"
                  value="2"
                  checked={starRating === "2"}
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="star">⭐⭐</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="starRating"
                  value="3"
                  checked={starRating === "3"}
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="star">⭐⭐⭐</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="starRating"
                  value="4"
                  checked={starRating === "4"}
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="star">⭐⭐⭐⭐</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="starRating"
                  value="5"
                  checked={starRating === "5"}
                  onChange={(e) => setStarRating(e.target.value)}
                />
                <span className="star">⭐⭐⭐⭐⭐</span>
              </label>
            </div></div>

            <div className="description-box-1">

            <h2>ADD A SMALL DESCRIPTION OF YOUR HOTEL</h2>
            <div className="hoteltitle">
             
            <textarea
                type="text"
                placeholder="Description"
                name="hotelDescription"
                value={formDescription.hotelDescription}
                onChange={handleChangeDescription}
                required
              />
              </div></div>

              <div className="add-photos">
            <h3>ADD SOME PHOTOS OF YOUR HOTEL<p>(Add atleast 5 photos of your property)</p></h3>
            
            
            <div>
              {images.length > 0 && (
                <div className="uploaded-image-container">
                  {images.map((image, index) => (
                    <div className="uploaded-image" key={index}>
                      {imgPerc > 0 && "Uploading: " + imgPerc + "%"}
                      <img src={URL.createObjectURL(image)} alt="Uploaded" width="100" />
                      <button type="button" onClick={() => handleRemoveImage(index)}>
                        <BiTrash style={{ color: 'red', fontSize: '18px' }} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                id="img"
                multiple
                onChange={handleFileChange}
              />
            </div>
            </div>

            <div className="add-photos">
            <h3>TELL GUESTS WHAT YOUR PLACE HAS OFFER</h3>
            <div className="amenities">
              {hotelservices?.map((item, index) => (
                <div
                  className={`service ${
                    hotelHighlights.includes(item.name) ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectHighlights(item.name)}
                >
                  <img src={item.image} alt="" />
                  <p>{item.name}</p>
                </div>
              ))}
            </div></div>
          
            <div className="add-photos">
            <h3>SELECT YOUR HOTEL'S VIEW</h3>
                <div className="place-view">
                  <select 
                    name="placeView" 
                    value={placeView} 
                    onChange={(e) => setPlaceView(e.target.value)} 
                    required
                  >
                    <option value="" disabled>Select your hotel view</option>
                    <option value="Lakeview">Lake View</option>
                    <option value="Cityview">City View</option>
                    <option value="Beachview">Beach View</option>
                    <option value="Islandview">Island View</option>
                    <option value="TinyHomes">Tiny Homes</option>
                    <option value="Mountainview">Mountain View</option>
                  </select>
                </div> 
            </div> 
            <div className="description-box">
          <h3>CONTACT NUMBER</h3>
                <div className="location">
                    <input
                        type="text"
                          placeholder="+94"
                          name="contactNumber"
                          value={contactNumber}
                          onChange={handleChangeContactNumber}
                          required
                            />
              </div></div>
            
            </div>
            

          
            
      {/* Your form inputs for adding images go here */}
      <button className="submit_btn" type="submit">
        NEXT
      </button>
    
        </form>
      </div>
      <Footer />
    </>
  );
};

export default CreateListing;
