import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import "../styles/CreateHotel.css";
import { IoIosImages } from "react-icons/io";
import Navbar2 from '../components/Navbar2';
import { BiTrash } from "react-icons/bi";
import { facilities, hoteltypes } from "../data";
import "../styles/CreateRestaurant.css";
import { RemoveCircleOutline, AddCircleOutline, ArrowBack } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import app from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import Swal from "sweetalert2";

export const CreateRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    creator,
    category,
    hoteltitle,
    hotelDescription,
    starRating,
    placeView,
    hotelHighlights,
    streetAddress,
    city,
    province,
    zipCode,
    contactNumber,
    uploadedImages=[],
    hotelId,
  } = location.state;


  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [type, setType] = useState("");
  const [guestCount, setGuestCount] = useState(1);
  const [bedroomCount, setBedroomCount] = useState(1);
  const [bedCount, setBedCount] = useState(1);
  const [bathroomCount, setBathroomCount] = useState(1);
  const [amenities, setAmenities] = useState([]);
  const [image, setImage] = useState(undefined);
  const [imgPerc, setImgPerc] = useState("");
  const [inputs, setInputs] = useState({});
  const [imageUploaded, setImageUploaded] = useState(false);
  const [formDescription, setFormDescription] = useState({
    title: "",
    description: "",
    highlight: "",
    highlightDesc: "",
    price: 0,
  });
  const [hotelDetails, setHotelDetails] = useState([]);

  useEffect(() => {
    const storedHotelDetails = localStorage.getItem(`hotelDetails-${hotelId}`);
    if (storedHotelDetails) {
      setHotelDetails(JSON.parse(storedHotelDetails));
    }
  }, [hotelId]);

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;
    setFormDescription({
      ...formDescription,
      [name]: value,
    });
  };

  const handleSelectAmenities = (facility) => {
    if (amenities.includes(facility)) {
      setAmenities((prevAmenities) =>
        prevAmenities.filter((option) => option !== facility)
      );
    } else {
      setAmenities((prev) => [...prev, facility]);
    }
  };



  const handlePost = (e) => {
    e.preventDefault();

    const roomId = uuidv4();

    const details = {
      id: roomId,
      title: formDescription.title,
      description:formDescription.description,
      highlight:formDescription.highlight,
      highlightDesc:formDescription.highlightDesc,
      price: formDescription.price,
      type: type,
      guestCount: guestCount,
      bedroomCount: bedroomCount,
      bedCount: bedCount,
      bathroomCount: bathroomCount,
      amenities: amenities,
      imageUrl: inputs.imgUrl,
      
    };

    const updatedHotelDetails = [...hotelDetails, details];
    setHotelDetails(updatedHotelDetails);

    localStorage.setItem(`hotelDetails-${hotelId}`, JSON.stringify(updatedHotelDetails));

    setFormDescription({
      title: "",
      description: "",
      highlight: "",
      highlightDesc: "",
      price: 0,
    });
    setType(""); // Clear selected type
  setAmenities([]); // Clear selected amenities
  setImage(undefined); // Clear selected image
  setImageUploaded(false); // Reset image uploaded flag
  setImgPerc(""); 
  setGuestCount(1);
  setBedroomCount(1);
  setBedCount(1);
  setBathroomCount(1);
  };

  const handleDelete = async (index) => {
    try {
      const updatedHotelDetails = [...hotelDetails];
      const deletedRoom = updatedHotelDetails.splice(index, 1)[0];
  
      // Delete the image from Firebase Storage
      if (deletedRoom.imageUrl) {
        const storage = getStorage();
        const imgRef = ref(storage, deletedRoom.imageUrl);
        await deleteObject(imgRef);
        console.log("Image deleted successfully");
      }
  
      setHotelDetails(updatedHotelDetails);
      localStorage.setItem(`hotelDetails-${hotelId}`, JSON.stringify(updatedHotelDetails));
    } catch (error) {
      console.error("Error deleting room:", error);
    }
  };

  const handleEditHotel = () => {
    navigate("/create-listing", {
      state: {
        creator,
        category,
        hoteltitle,
        hotelDescription,
        starRating,
        placeView,
        streetAddress,
        hotelHighlights,
        city,
        province,
        zipCode,
        uploadedImages,
        hotelId,
      },
    });
  };

  const handlePublishHotel = async () => {
    try {
        const formData = new FormData();

        // Append hotel data
        formData.append('hotelId', hotelId);
        formData.append('creator', creator);
        formData.append('category', category);
        formData.append('hoteltitle', hoteltitle);
        formData.append('hotelHighlights', hotelHighlights);
        formData.append('hotelDescription', hotelDescription);
        formData.append('starRating', starRating);
        formData.append('placeView', placeView);
        formData.append('streetAddress', streetAddress);
        formData.append('city', city);
        formData.append('province', province);
        formData.append('zipCode', zipCode);
        formData.append('contactNumber', contactNumber);
        formData.append("approve", false);
        uploadedImages.forEach((photo, index) => {
            formData.append(`hotelPhotos[${index}]`, photo);
        });

        // Append room details
        hotelDetails.forEach((detail, index) => {
          formData.append(`rooms[${index}][title]`, detail.title);
          formData.append(`rooms[${index}][description]`, detail.description);
          formData.append(`rooms[${index}][highlight]`, detail.highlight);
          formData.append(`rooms[${index}][highlightDesc]`, detail.highlightDesc);
          formData.append(`rooms[${index}][price]`, detail.price);
          formData.append(`rooms[${index}][type]`, detail.type);
          formData.append(`rooms[${index}][guestCount]`, detail.guestCount);
          formData.append(`rooms[${index}][bedroomCount]`, detail.bedroomCount);
          formData.append(`rooms[${index}][bedCount]`, detail.bedCount);
          formData.append(`rooms[${index}][bathroomCount]`, detail.bathroomCount);
          formData.append(`rooms[${index}][amenities]`, detail.amenities.join(','));
          formData.append(`rooms[${index}][imageUrl]`, detail.imageUrl);
        });

                  console.log('Preparing to send hotel data:');
          formData.forEach((value, key) => {
              console.log(key, value);
          });

        const response = await fetch(`${API_BASE_URL}/properties/create/hotel`, {
            method: "POST",
            body: formData,
        });

        if (response.ok) {
          Swal.fire({
            title: "Your Property has been created Successfully! It is under Review for approval",
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              popup: 'swal-custom'
            }
          }).then(() => {
            navigate("/");
          });
        } else {
            console.error("Failed to create/update listing");
        }

        const data = await response.json();
        console.log('Response from server:', data);
    } catch (error) {
        console.error('Error publishing hotel:', error);
    }
};
 const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<FontAwesomeIcon key={i} icon={faStar} style={{ color: 'yellow' }} />);
    }
    return stars;
  };

  useEffect(() => {
    if (image) {
      uploadFile(image, "imgUrl");
    }
  }, [image]);

  const uploadFile = (file, fileType) => {
    const storage = getStorage();
    const folder = "roomImages/";
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName); 
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setImgPerc(Math.round(progress));
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
        default:
          break;
      }
    }, 
  
  (error) => {
    console.log(error)
    switch (error.code) {
      case 'storage/unauthorized':
        console.log(error);
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;
      case 'storage/unknown':
        // Unknown error occurred, inspect error.serverResponse
        break;
      default:
        break;
    }
  }, 
  () => {
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('downloadURL - ', downloadURL);
      setInputs((prev) => {
        return {
          ...prev,
          [fileType]: downloadURL,
        };
      });
      setImageUploaded(true);
    });
  }
  );
  }

  const handleRemoveImage = () => {
    if (inputs.imgUrl) {
      const storage = getStorage();
      const imgRef = ref(storage, inputs.imgUrl);
      deleteObject(imgRef).then(() => {
        console.log("Image deleted successfully");
      }).catch((error) => {
        console.log("Error deleting image:", error);
      });
    }
    setImage(undefined);
    setInputs((prev) => {
      const { imgUrl, ...rest } = prev;
      return rest;
    });
    setImageUploaded(false);
    setImgPerc("");
  };

  

return (
  <>
    <Navbar2 />
    <div className="create-hotel">
      <h1></h1>
      <div className="crmhotel">
        <h2>YOUR HOTEL</h2><hr/>
        <div className="crhotel">
          <div className='crphoto'>
          <div className="photos">
            {uploadedImages.length > 0 && (
              <img
                src={uploadedImages[0]}
                alt={`Main Photo`}
              />
            )}
          </div>

            
          </div>
          

          <div className="crcontent">
          <table>
  <tr>
    <th className='th-hotel'>Hotel Name</th>
    <td className='td-hotel'>{hoteltitle}</td>
  </tr>
  <tr>
    <th className='th-hotel'>Location</th>
    <td className='td-hotel'>{`${streetAddress}, ${city}, ${province}, ${zipCode}`}</td>
  </tr>
  <tr>
    <th className='th-hotel'>Highlights</th>
    <td className='td-hotel'>{hotelHighlights}</td>
  </tr>
  <tr>
    <th className='th-hotel'>Category</th>
    <td className='td-hotel'>{category}</td>
  </tr>

  <tr>
    <th className='th-hotel'>Rating</th>
    <td className='td-hotel'>{renderStars(starRating)}</td>
  </tr>
  <tr>
    <th className='th-hotel'>View</th>
    <td className='td-hotel'>{placeView}</td>
  </tr>
</table>

          </div>
          {/* <ArrowBack onClick={handleEditHotel} className="edit-button" /> */}
        </div>
      </div>

      <div className="crroom">
        <h2>YOUR ROOM(S)</h2><hr/>
        {hotelDetails.map((detail, index) => (
          <div key={index} className="crroomc">
            <div className="crroomsd">
              <p>{detail.title}</p>
              <img src={detail.imageUrl} alt="kill" />
            </div>
            <div className="crroomst">
            <table className='room-details-table'>
    <tbody>
      <tr className='tr-hotel'>
        <th className='th-hotel'>Type</th>
        <td className='td-hotel'>{detail.type}</td>
      </tr>
      <tr className='tr-hotel'>
        <th className='th-hotel'>Price Per Night</th>
        <td className='td-hotel'>Rs. {detail.price}</td>
      </tr>
      <tr className='tr-hotel'>
        <th className='th-hotel'>Facilities</th>
        <td className='td-hotel'>{detail.amenities.join(', ')}</td>
      </tr>
    </tbody>
  </table>
            </div>
            <BiTrash onClick={() => handleDelete(index)} />
          </div>
        ))}
      </div>

      <form onSubmit={handlePost}>
        <div className="create-listing_step2">
        <div className="step-heading">
<div className="step-number">STEP:02</div>
<div className="step-description">MAKE YOUR HOTEL STANDS OUT</div>

</div>
          
<div className="description-box">
          <h3>WHAT TYPE OF PLACE WILL GUESTS HAVE?</h3>
          <div className="type-list">
            {hoteltypes?.map((item, index) => (
              <div
                className={`type ${type === item.name ? "selected" : ""}`}
                key={index}
                onClick={() => setType(item.name)}
              >
                <div className="type_text">
                  <h4>{item.name}</h4>
                  <p>{item.description}</p>
                </div>
                <div className="type_icon">{item.icon}</div>
              </div>
            ))}
          </div></div>

<div className="description-box">
          <h3>SHARE SOME BASICS OF YOUR ROOM</h3>
          <div className="basics">
            <div className="basic">
              <p>Guests</p>
              <div className="basic_count">
                <RemoveCircleOutline
                  onClick={() => {
                    guestCount > 1 && setGuestCount(guestCount - 1);
                  }}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "gray" },
                  }}
                />
                <p>{guestCount}</p>
                <AddCircleOutline
                  onClick={() => setGuestCount(guestCount + 1)}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
              </div>
            </div>
            <div className="basic">
              <p>Bedrooms</p>
              <div className="basic_count">
                <RemoveCircleOutline
                  onClick={() => {
                    bedroomCount > 1 && setBedroomCount(bedroomCount - 1);
                  }}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
                <span>{bedroomCount}</span>
                <AddCircleOutline
                  onClick={() => setBedroomCount(bedroomCount + 1)}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
              </div>
            </div>
            <div className="basic">
              <p>Beds</p>
              <div className="basic_count">
                <RemoveCircleOutline
                  onClick={() => {
                    bedCount > 1 && setBedCount(bedCount - 1);
                  }}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
                <span>{bedCount}</span>
                <AddCircleOutline
                  onClick={() => setBedCount(bedCount + 1)}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
              </div>
            </div>
            <div className="basic">
              <p>Bathrooms</p>
              <div className="basic_count">
                <RemoveCircleOutline
                  onClick={() => {
                    bathroomCount > 1 && setBathroomCount(bathroomCount - 1);
                  }}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
                <p>{bathroomCount}</p>
                <AddCircleOutline
                  onClick={() => setBathroomCount(bathroomCount + 1)}
                  sx={{
                    fontSize: "25px",
                    cursor: "pointer",
                    "&:hover": { color: "#0C4670" },
                  }}
                />
              </div>
            </div>
          </div></div>
          <div className="description-box">
          <h3>WHAT MAKES YOUR HOTEL ATTRACTIVE & EXCITING?</h3>
          <div className="description">
            <p>Title</p>
            <input
              type="text"
              placeholder="Title"
              name="title"
              value={formDescription.title}
              onChange={handleChangeDescription}
              required
            />
            <p>Description</p>
            <textarea
              type="text"
              placeholder="Description"
              name="description"
              value={formDescription.description}
              onChange={handleChangeDescription}
              required
            />
            <p>Highlight</p>
            <input
              type="text"
              placeholder="Highlight"
              name="highlight"
              value={formDescription.highlight}
              onChange={handleChangeDescription}
              required
            />
            <p>Highlight details</p>
            <textarea
              type="text"
              name="highlightDesc"
              placeholder="Highlight Description"
              value={formDescription.highlightDesc}
              onChange={handleChangeDescription}
              required
            />
            <p>Set Your Price</p>
            <span>Rs.</span>
            <input
              type="number"
              placeholder="Price per night"
              name="price"
              value={formDescription.price}
              onChange={handleChangeDescription}
              className="price"
              required
            />
          </div></div>
          <div className="description-box">
          <h3>ADD SOME PHOTOS OF YOUR ROOM(S)</h3>
          <label htmlFor='img'></label>
          {imgPerc > 0 && "Uploading: " + imgPerc + "%"}
                <br />
                {imageUploaded ? (
                  <div className="uploaded-image-container">
                    <img src={inputs.imgUrl} alt="Uploaded" width="100" />
                    <button type="button" onClick={handleRemoveImage}>
                    <BiTrash style={{ color: 'red', fontSize: '18px' }} />
                    </button>
                  </div>
                ) : (
                  <input
                    type="file"
                    accept="images/*"
                    id="img"
                    onChange={(e) => setImage((prev) => e.target.files[0])}
                  />
                )}
         </div>
          <div className="description-box">
          <h3>TELL GUESTS WHAT YOUR PLACE HAS TO OFFER</h3>
          <div className="amenities">
            {facilities?.map((item, index) => (
              <div
                className={`facility ${
                  amenities.includes(item.name) ? "selected" : ""
                }`}
                key={index}
                onClick={() => handleSelectAmenities(item.name)}
              >
                <div className="facility_icon">{item.icon}</div>
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        </div>
        <button className="submit_btn" type="submit">
          ADD YOUR ROOM
        </button>
      </form>

      <button className="submit_btn" type="button" onClick={handlePublishHotel}>
        PUBLISH YOUR HOTEL
      </button>
    </div>
    <Footer />
  </>
);
};

export default CreateRoom;
