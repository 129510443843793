import "../styles/PropertyList.css";
import { useDispatch, useSelector } from "react-redux";

import ListingCard from "../components/ListingCard";
import { useEffect, useState } from "react";
import { setPropertyList } from "../redux/state";
import Loader from "../components/Loader";
import Footer from "../components/Footer"
import Navbar2 from "../components/Navbar2";
import PropertyCard from "../components/PropertyCard";

const PropertyList = () => {
  const [loading, setLoading] = useState(true)
  const user = useSelector((state) => state.user)
  const propertyList = user?.propertyList;
  console.log(user)
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch()
  const getPropertyList = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/${user._id}/properties`, {
        method: "GET"
      })
      const data = await response.json()
      console.log(data)
      dispatch(setPropertyList(data))
      setLoading(false)
    } catch (err) {
      console.log("Fetch all properties failed", err.message)
    }
  }

  useEffect(() => {
    getPropertyList()
  }, [])

  return loading ? <Loader /> : (
    <>
      <Navbar2 />
      <div className="property-list">
        <h1>Your Property List</h1>
      <div className="list-pro">
        {propertyList?.map(
          ({
            _id,
            creator,
            listingPhotoPaths,
            hotelPhotos,
            city,
            imgUrls,
            province,
            category,
            hoteltitle,
            type,
            title,
            price,
            model,
           
          }) => (
            <PropertyCard 
              listingId={_id}
              title={title}
              creator={creator}
              hoteltitle={hoteltitle}
              listingPhotoPaths={listingPhotoPaths}
              city={city}
              imgUrls={imgUrls}
              province={province}
              hotelPhotos={hotelPhotos}
              category={category}
              type={type}
              price={price}
              model={model}
            />
          )
        )}
      </div>
      </div>
      <Footer />
    </>
  );
};

export default PropertyList;
