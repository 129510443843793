import { useState, useEffect } from "react";
import "../styles/List.css";
import CommonSection from "../shared/commonSection/CommonSection";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setListings } from "../redux/state";
import Loader from "../components/Loader";
import { activitytypes } from "../data";
import { activityPackages } from "../data";
import { AiOutlineClose } from "react-icons/ai";
import Footer from "../components/Footer";
import ActivityCard from "../components/ActivityCard";
import { BiFilter } from "react-icons/bi";


const CategoryPage = () => {
  const [loading, setLoading] = useState(true);
  const { category } = useParams();
const [activityCount, setActivityCount] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const dispatch = useDispatch();
  const listings = useSelector((state) => Array.isArray(state.listings) ? state.listings : []); // Ensure listings is an array

  const [filters, setFilters] = useState({
    place: "",
    maxPrice: 100000,
    activityPackages:[],
   
    activitytypes:[]
  });

  const getFeedListings = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/activities/search/all`, {
        method: "GET",
      });

      const data = await response.json();
      console.log(data); // Debug: Check what data is received
      if (Array.isArray(data)) {
        const approvedListings = data.filter(listing => listing.approve === true);
        dispatch(setListings({ listings: approvedListings }));
      } else {
        console.error("Expected an array but got", typeof data);
      }
      setLoading(false);
    } catch (err) {
      console.log("Fetch Listings Failed", err.message);
    }
  };

  useEffect(() => {
    getFeedListings();
  }, []);

useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/stats`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        setActivityCount(data.activity);
        
      } catch (err) {
        console.error('Failed to fetch counts:', err);
      }
    };

    fetchCounts();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };


 

  const handleActivitytypeChange = (e) => {
    const { value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      type: prevFilters.type === value ? "" : value,
    }));
  };

  const handleactivitypackageChange = (e) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      if (checked) {
        return {
          ...prevFilters,
          activityPackages: [...prevFilters.activityPackages, value],
        };
      } else {
        return {
          ...prevFilters,
          activityPackages: prevFilters.activityPackages.filter(
            (activityPackage) => activityPackage !== value
          ),
        };
      }
    });
  };

  const filteredListings = listings.filter((listing) => {
    
    // Check if the district matches the selected place filter
    const placeMatches =
      !filters.place ||
      listing.city.toLowerCase().includes(filters.place.toLowerCase());
      const priceMatches = listing.price <= filters.maxPrice;

          // Check if any selected facility matches any of the amenities of the listing
          const typeMatches = !filters.type || listing.type === filters.type;

          const activitypackageMatches =
          filters.activityPackages.length === 0 ||
          (listing.amenities && listing.amenities.some((amenity) =>
            filters.activityPackages.some((activityPackage) =>
              listing.amenities.some((amenity) => {
                const amenitiesArray = amenity.split(",").map((a) => a.trim());
                return amenitiesArray.includes(activityPackage);
              })
            )
          ));



      return placeMatches&& priceMatches&& typeMatches&& activitypackageMatches;
    
  });

  const handlePriceChange = (e) => {
    const { value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      maxPrice: Number(value),
    }));
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Navbar />
      <div>
        <CommonSection title="Activities" />
      </div>
      <div className="content">
      <div className={`filter-box ${showFilter ? 'show' : ''}`}>
          <h3>Filters Your Choices</h3>
          <div className="filter-item">
            <label htmlFor="place">Place</label>
            <input
              type="text"
              id="place"
              name="place"
              value={filters.place}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
          <label><h3>Price Range</h3></label>
          <div className="price-range">
            <input
              type="range"
              min="0"
              max="100000"
              value={filters.maxPrice}
              onChange={handlePriceChange}
             
            />
            <span>{filters.maxPrice}</span>
          </div>
        </div>
        <div className="filter-item">
              <label><h3>Activity Type</h3></label>
              {activitytypes.map((activitytype) => (
                <div key={activitytype.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={activitytype.name}
                    name="facilities"
                    value={activitytype.name}
                    onChange={handleActivitytypeChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={activitytype.name}>{activitytype.name}</label>
                </div>
              ))}
            </div>

            <div className="filter-item">
              <label><h3>Amenities</h3></label>
              {activityPackages.map((activityPackage) => (
                <div key={activityPackage.name} className="facility-option">
                  <input
                    type="checkbox"
                    id={activityPackage.name}
                    name="facilities"
                    value={activityPackage.name}
                    onChange={handleactivitypackageChange}
                    className="checkbox-large"
                  />
                  <label htmlFor={activityPackage.name}>{activityPackage.name}</label>
                </div>
              ))}
            </div>

        </div>
        <div className="list">
          {filteredListings.map(
            ({
              _id,
              creator,
              listingPhotoPaths,
              city,
              province,
              imgUrls,
              title,
              category,
              type,
              price,
              
            }) => (
              <ActivityCard
                key={_id}
                listingId={_id}
                title={title}
                creator={creator}
                imgUrls={imgUrls}
                listingPhotoPaths={listingPhotoPaths}
                city={city}
                province={province}
                category={category}
                type={type}
                price={price}
                
              />
            )
          )}
        </div>
      </div>
      <div><button className="filter-button" onClick={toggleFilter}>
        <BiFilter/>
      </button></div> 
      <Footer />
    </>
  );
};

export default CategoryPage;
