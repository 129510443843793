import React from "react";
import "./common-section.css";

const CommonSection = ({ title }) => {
  let sectionClass = "common-section";

  if (title === "Hotels") {
    sectionClass += " hotel-background";
  } else if (title === "Activities") {
    sectionClass += " activity-background";
  }
  else if (title === "Restaurants") {
    sectionClass += " restaurant-background";
  }
  else if (title === "Products") {
    sectionClass += " product-background";
  }
  else if (title === "Blogs") {
    sectionClass += " blog-background";
  }
  else if (title === "Contact Us") {
    sectionClass += " contact-background";
  }
  else if (title === "Vehicle Rent") {
    sectionClass += " rent-background";
  }

  return (
    <section className={sectionClass}>
      <h1>{title}</h1>
    </section>
  );
};

export default CommonSection;
