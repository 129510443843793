import React, { useState, useEffect } from 'react';
import CommonSection from "../shared/commonSection/CommonSection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faUtensils, faCar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import aboutimg1 from "../images/about-1.jpg";
import aboutimg2 from "../images/about-2.jpg";
import aboutimg3 from "../images/about-3.jpg";
import aboutimg4 from "../images/about-4.jpg";
import whyChooseImg1 from "../images/reason-2.jpg";
import whyChooseImg2 from "../images/reason-11.jpg";
import whyChooseImg3 from "../images/reason-3.jpg";
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/AboutUs.css";
import { Facebook, Instagram, YouTube } from '@mui/icons-material';

const AboutPage = () => {
  const [roomCount, setRoomCount] = useState(0);
  const [staffCount, setStaffCount] = useState(0);
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [clientCount, setClientCount] = useState(0);
  // const [currentSlide, setCurrentSlide] = useState(0);
  const videos = [
    { platform: 'YouTube', url: 'https://www.youtube.com/embed/buns7DpTL_I?si=mQtdIcGec3kmYFIb',videoId: 'tgbNymZ7vqY' },
    { platform: 'Facebook', url: 'https://www.youtube.com/embed/buns7DpTL_I?si=mQtdIcGec3kmYFIb',videoId: 'tgbNymZ7vqY' },
    { platform: 'Instagram', url: 'https://www.youtube.com/embed/buns7DpTL_I?si=mQtdIcGec3kmYFIb',videoId: 'tgbNymZ7vqY' },
    { platform: 'TikTok', url: 'https://www.youtube.com/embed/buns7DpTL_I?si=mQtdIcGec3kmYFIb',videoId: 'tgbNymZ7vqY' },
    // Add more video objects here
  ];

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/stats`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setRoomCount(data.hotellistings);
        setStaffCount(data.restaurantlistings);
        setClientCount(data.rentlistings);
      } catch (err) {
        console.error('Failed to fetch counts:', err);
      }
    };

    fetchCounts();
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentSlide((prev) => (prev + 1) % videos.length);
  //   }, 7000); // Change slide every 7 seconds

  //   return () => clearInterval(interval);
  // }, [videos.length]);

  // const nextSlide = () => {
  //   setCurrentSlide((prev) => (prev + 1) % videos.length);
  // };

  // const prevSlide = () => {
  //   setCurrentSlide((prev) => (prev - 1 + videos.length) % videos.length);
  // };

  

  return (
    <>
      <Navbar />
      <section className='about'>
        <div>
          <CommonSection title={"About Us"} />
        </div>
        <div className="container">
          <div className="about-container">
            <section className="about-us">
              <div className='about-content'>
                <h1>WELCOME TO <span className="highlight-1">EXPLORING LANKA</span></h1>
                <p>At Exploring Lanka, your adventure begins with endless possibilities and unparalleled experiences. Immerse yourself in the vibrant tapestry of Sri Lanka's culture, nature, and hospitality. From ancient wonders to hidden gems, our tailored journeys promise to unveil the essence of this captivating island. Let us be your compass as you navigate through the wonders of Sri Lanka!</p>
              </div>
              <div className="stats">
                <div className="stat">
                  <div className="icon">
                    <FontAwesomeIcon icon={faHotel} />
                  </div>
                  <div className="number">{roomCount}</div>
                  <div className="label">Hotels</div>
                </div>
                <div className="stat">
                  <div className="icon">
                    <FontAwesomeIcon icon={faUtensils} />
                  </div>
                  <div className="number">{staffCount}</div>
                  <div className="label">Restaurants</div>
                </div>
                <div className="stat">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCar} />
                  </div>
                  <div className="number">{clientCount}</div>
                  <div className="label">Rents</div>
                </div>
              </div>
            </section>
            <section className="gallery">
              <img src={aboutimg1} alt="Pool view" className='about1' />
              <img src={aboutimg2} alt="Resort" className='about2' />
              <img src={aboutimg3} alt="Hotel view" className='about3' />
              <img src={aboutimg4} alt="Relaxing" className='about4' />
            </section>
          </div>
        </div>
        <div className="why-choose-us">
          <div className='why-choose-us-text'>
            <h2>WHY CHOOSE US?</h2>
            <p>Choose us for personalized travel experiences tailored to your preferences, and expert guidance from seasoned locals who know Sri Lanka inside out. Let us transform your dream vacation into reality with unmatched expertise and dedication to your satisfaction!</p>
          </div>
          <div className="why-choose-container">
            <div className="why-choose-item small">
              <img src={whyChooseImg1} alt="Reason 1" />
              <div className='text-box'>
                <h3>Best Deals</h3>
                <p>Unlock unbeatable deals and exclusive offers on accommodations, activities, and more, tailored to suit every budget and preference. Don't miss out on the chance to make the most of your Sri Lankan adventure without breaking the bank!</p>
              </div>
            </div>
            <div className="why-choose-item large">
              <img src={whyChooseImg2} alt="Reason 2" />
              <div className='text-box'>
                <h3>Fast Services</h3>
                <p>Experience seamless and efficient service with our dedicated team, ensuring swift and hassle-free arrangements for your travel needs. Trust us to make every moment count on your Sri Lankan journey with our prompt and reliable assistance!</p>
              </div>
            </div>
            <div className="why-choose-item small">
              <img src={whyChooseImg3} alt="Reason 3" />
              <div className='text-box'>
                <h3>Great Team</h3>
                <p>Our exceptional team is your ultimate travel companion, bringing passion, expertise, and personalized attention to every step of your journey. With our dedicated professionals by your side, your Sri Lankan adventure is guaranteed to be nothing short of extraordinary!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="explore-vlogs">
          <h2>EXPLORE OUR VLOGS</h2>
          <div className="vlogs-container">
            <div className="vlogs-container-text">
              <p>Dive into our captivating vlogs and embark on virtual journeys that bring the beauty and excitement of Sri Lanka to life. Let our immersive storytelling and stunning visuals inspire your wanderlust and ignite your sense of adventure!</p>
            </div>
            
            <div className="vlogs-container-video">

                <div className="video-container-youtube">
                  <div className="videocontainer">
                    <h1 style={{color:'#ff0000'}}>Explore Our Youtube Vlogs</h1>
                    <YouTube className='youtube'/>
                  </div>
                  <div className="video-overlay">
                    <h2>Click here</h2>
                  </div>
                </div>
                <div className="video-container-insta">
                <div className="videocontainer">
                    <h1 style={{color:'#ff00c3'}}>Explore Our Instagram Vlogs</h1>
                    <a href="https://www.instagram.com/exploring._lanka?igsh=djU2YnV2dHFlemhw&utm_source=qr" target="_blank" rel="noopener noreferrer">
                      <Instagram className="insta"/>
                    </a>
                  </div>
                  <div className="video-overlay">
                  <h2>Click here</h2>
                  </div>
                </div>
                <div className="video-container-tiktok">
                <div className="videocontainer">
                    <h1>Explore Our TikTok Vlogs</h1>
                    <a href="https://www.tiktok.com/@exploringlanka1?_t=8n18ajMtnc7&_r=1" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTiktok} className='tik' />
                    </a>
                  </div>
                  <div className="video-overlay">
                    <h2>Click here</h2>
                  </div>
                </div>
                <div className="video-container-facebook">
                <div className="videocontainer">
                    <h1 style={{color:'#4400ff'}}>Explore Our Facebook Vlogs</h1>
                    <a href="https://www.facebook.com/profile.php?id=61558661799506&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                      <Facebook className="facebook"/>
                    </a>
                  </div>
                  <div className="video-overlay">
                    <h2>Click here</h2>
                  </div>
                </div>
              
            {/* <div className="img-background-bg">
                  <img src={aboutimg4} alt="third Background" className="background-img"/>
              </div>
            <div className="img-background">
                  <img src={aboutimg3} alt="Second Background" className="background-img"/>
              </div>
              <div className="img-before">
                <img src={aboutimg1} alt="Background" className="background-img"/>
              </div>
              <img src={aboutimg2} alt="Main" className="img"/> */}
            {/* {videos.map((video, index) => {
              let className = 'vlog-item';
              if (index === currentSlide) {
                className += ' active';
              } else if (index === (currentSlide - 1 + videos.length) % videos.length) {
                className += ' left';
              } else if (index === (currentSlide + 1) % videos.length) {
                className += ' right';
              }
              return (
                <div className={className} key={index}>
                  <iframe src={video.url} title={video.platform}></iframe>
                </div>
              );
            })} */}
            </div>
            
          </div>
         
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutPage;
