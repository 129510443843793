import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import CreateListing from "./pages/CreateListing";

import TripList from "./pages/TripList";
import WishList from "./pages/WishList";
import PropertyList from "./pages/PropertyList";
import ReservationList from "./pages/ReservationList";
import CategoryPage from "./pages/CategoryPage";
import SearchPage from "./pages/SearchPage";

import { RestaurantPage } from './pages/RestaurantPage';

import  BlogPage  from './pages/BlogPage';
import  ContactUsPage  from './pages/ContactUsPage';
import { SelectCategoryPage } from './pages/SelectCategoryPage';
import { CreateActivies } from "./pages/CreateActivies";
import ActivityPage from './pages/ActivityPage';
import { ProductPage } from './pages/ProductPage';
import HotelListingDetails from "./pages/HotelListingDetails";
import ActivityListingDetails from "./pages/ActivityListingDetails";
import BlogPost from './pages/BlogPost';
import { CreateRoom } from './pages/CreateRoom';
import { CreateRestaurant } from './pages/CreateRestaurant';
import { RestaurantListingDetails } from "./pages/RestaurantListingDetails";
import { CreateVehicleRent } from "./pages/CreateVehicleRent";
import RentVehiclePage from "./pages/RentVehiclePage";
import RentVehicleDetails from "./pages/RentVehicleDetails";
import ProductDetails from "./pages/ProductDetailPage";



function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/properties/category/activity" element={<ActivityPage />} />
          <Route path="/properties/category/restaurant" element={<RestaurantPage/>} />
          <Route path="/create-activities" element={<CreateActivies />} />
          <Route path="/properties/category/vehiclerent" element={<RentVehiclePage />} />
          <Route path="/selectcategory" element={<SelectCategoryPage/>} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/create-room" element={<CreateRoom />} />
          <Route path="/create-restaurant" element={<CreateRestaurant />} />
          <Route path="/create-vehiclerent" element={<CreateVehicleRent />} />
          <Route path="/create-listing" element={<CreateListing />} />
          <Route path="/properties/:listingId/update" element={<CreateListing />} />
          <Route path="/restaurants/:listingId/update" element={<CreateRestaurant/>} />
          <Route path="/Hotel/detail/:listingId" element={<HotelListingDetails />} />
          <Route path="/products/detail/:listingId" element={<ProductDetails />} />
          <Route path="/activities/detail/:listingId" element={<ActivityListingDetails/>} />
          <Route path="/restaurants/detail/:listingId" element={<RestaurantListingDetails/>} />
          <Route path="/rentvehicle/detail/:listingId" element={<RentVehicleDetails/>} />
          <Route path="/product" element={<ProductPage/>} />
          <Route path="/properties/category/hotel" element={<CategoryPage />} />
          <Route path="/properties/search/:search" element={<SearchPage />} />
          <Route path="/:userId/trips" element={<TripList />} />
          <Route path="/:userId/wishList" element={<WishList />} />
          <Route path="/:userId/properties" element={<PropertyList />} />
          <Route path="/:userId/reservations" element={<ReservationList />} />
          <Route path="/post/:postId" element={<BlogPost />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
