import Navbar from "../components/Navbar"
import Slide from "../components/Slide"
import Categories from "../components/Categories"
import Footer from "../components/Footer"
import { About } from "../components/About"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Product } from "../components/Product"
import { Blog } from "../components/Blog"
import "../styles/Home.css";

const HomePage = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '+94703517825';
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };
  return (
    <>
      <Navbar /> 
      <Slide />
      <About/>
      <Categories />
      <Product/>
      <Blog/>     
      <Footer />     
      <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </div>
    </>
  )
}

export default HomePage
