import React, { useEffect, useState } from "react";
import { Search, Person, Menu, ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import "../styles/Navbar2.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { setLogout } from "../redux/state";
import { Container } from "@mui/material";

const Navbar2 = () => {
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [serviceDropdown, setServiceDropdown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
	const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navItems = [
    { path: "/", title: "Home" },
    { path: "/about", title: "About" },
    { path: "/product", title: "Product" },
    { path: "/blog", title: "Blog" },
    { path: "/contactus", title: "Contact Us" },
  ];

  const serviceItems = [
    { path: "/properties/category/hotel", title: "Hotels" },
    { path: "/properties/category/restaurant", title: "Restaurant" },
    { path: "/properties/category/vehiclerent", title: "Vehicle Rent" },
    { path: "/properties/category/activity", title: "Activities" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`navbar2 ${isScrolled ? "" : "transparent2"}`}>
      <div className="navbar2items">
        <img src="/assets/logo.png" alt="logo" />
        <div className="navitems">
          <ul>
            {navItems.slice(0, 2).map(({ path, title }) => (
              <li key={path}>
                <NavLink to={path} activeClassName="active" exact>
                  {title}
                </NavLink>
              </li>
            ))}
            <li className="service-dropdown">
              <button
                onClick={() => setServiceDropdown(!serviceDropdown)}
                className="service-button"
              >
                Service {serviceDropdown ? <ArrowDropUp /> : <ArrowDropDown />}
              </button>
              {serviceDropdown && (
                <div className="service-dropdown-content">
                  {serviceItems.map(({ path, title }) => (
                    <Link key={path} to={path}>
                      {title}
                    </Link>
                  ))}
                </div>
              )}
            </li>
            {navItems.slice(2).map(({ path, title }) => (
              <li key={path}>
                <NavLink to={path} activeClassName="active" exact>
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
          <button
            className="navbar2_right_account"
            onClick={() => setDropdownMenu(!dropdownMenu)}
          >
            {!user ? (
              <Person sx={{ color: '#0C4670' }} style={{fontSize:40}}/>
            ) : (
              <img
                src={`${API_BASE_URL}/${user.profileImagePath.replace(
                  "public",
                  ""
                )}`}
                alt="profile photo"
                style={{ objectFit: "cover", borderRadius: "50%", height:50 , width:50 , padding:2}}
              />
            )}
          </button>
          {dropdownMenu && !user && (
            <div className="navbar2_right_accountmenu">
              <Link to="/login">Log In</Link>
              <Link to="/register">Sign Up</Link>
            </div>
          )}
          {dropdownMenu && user && (
            <div className="navbar2_right_accountmenu">
              <Link to={`/${user._id}/wishList`}>Wish List</Link>
              <Link to={`/${user._id}/properties`}>Property List</Link>
              <Link to="/selectcategory">Become a Seller</Link>
              <Link
                to="/login"
                onClick={() => {
                  dispatch(setLogout());
                }}
              >
                Log Out
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar2;
