import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/BlogPost.css';
import { FaTags } from 'react-icons/fa'; 
import CommonSection from "../shared/commonSection/CommonSection";

const dummyData = [
  {
    id: 1,
    title: "First Blog Post",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...",
  },
  {
    id: 2,
    title: "Second Blog Post",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 2, 2024",
    categories: ["Product", "Inspiration"],
    Content: "This is the full content of the second blog post. It can be quite long and detailed...",
  },
  {
    id: 3,
    title: "Third Blog Post",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 3, 2024",
    categories: ["Product", "Inspiration"],
    Content: "This is the full content of the third blog post. It can be quite long and detailed...",
  },
  {
    id: 4,
    title: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
  },
  {
    id: 5,
    title: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
  },
  {
    id: 6,
    title: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
  },
  {
    id: 7,
    title: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
  },
  {
    id: 8,
    title: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
  },
  {
    id: 9,
    title: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
  },
  {
    id: 10,
    title: "How to Make a WordPress Website – Step-by-Step Guide for Beginners",
    imageUrl: "https://img.freepik.com/premium-photo/travel-world-monuments-concept_117023-14.jpg",
    date: "June 1, 2024",
    categories: ["Restaurant food", "Travel news", "Modern technology"],
    Content: "This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...This is the full content of the first blog post. It can be quite long and detailed...",
  },
];

const BlogPost = () => {
  const { postId } = useParams();
  const post = dummyData.find((post) => post.id === parseInt(postId));

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <>
      <Navbar />
      <div>
        <CommonSection title="About Us" />
      </div>
      <div className="post-page">
        <h3>{post.title}</h3>
        <img src={post.imageUrl} alt={`Blog Post ${post.id}`} />
        <p className="date">Published on {post.date}</p>
        <p className="category"><FaTags style={{ marginRight: '5px' }} /> {post.categories.join(', ')}</p>
        <p>{post.Content}</p>
        <Link to="/blog"className="back-link">
          &#8592; Back to Blog Page {/* Unicode arrow character */}
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default BlogPost;
