
import {

  GiButterflyWarning,
  GiCampingTent,
  GiFluffyWing,
  GiFlyingBeetle,
  GiFlyingDagger,
  GiFlyingShuriken,
  GiFlyingTrout,
  GiJumpingRope,
 
} from "react-icons/gi";
import {
  FaPumpSoap,
  FaShower,
  FaFireExtinguisher,
  FaUmbrellaBeach,
  FaKey,
  FaToilet,FaThumbsUp, FaStreetView, FaUtensils, FaGamepad, FaSpa
} from "react-icons/fa";
import { FaHouseUser, FaPeopleRoof, FaKitchenSet, FaMarsAndVenusBurst, FaSpaghettiMonsterFlying } from "react-icons/fa6";
import {
  BiSolidWasher,
  BiSolidDryer,
  BiSolidFirstAid,
  BiWifi,
  BiSolidFridge,
  BiCycling,
  BiGame,
  BiHealth,
  BiDumbbell,

} from "react-icons/bi";
import { BsSnow, BsFillDoorOpenFill, BsPersonWorkspace } from "react-icons/bs";

import {  MdMicrowave, MdBalcony, MdYard, MdPets ,MdAccessTime } from "react-icons/md";
import {
  PiBathtubFill,
  PiCoatHangerFill,
  PiFlyingSaucer,
  PiThumbsUp,
  PiTelevisionFill,
  PiVan,
  PiCar,
} from "react-icons/pi";
import { TbIroning3, TbMotorbike, TbShiJumping } from "react-icons/tb";
import {
  GiHeatHaze,
  GiCctvCamera,
  GiBarbecue,
  GiToaster,
  GiCampfire,
} from "react-icons/gi";
import { AiFillCar } from "react-icons/ai";
import { BikeScooter, BikeScooterSharp, BikeScooterTwoTone, CarRental, Hiking, Hotel, Restaurant } from "@mui/icons-material";
import {backimg} from "../src/images/door.svg";




export const categories = [
  // {
  //   label: "All",
  //   icon: <BiWorld />,
  // },
  {
    img: "assets/hotel.jpg",
    label: "Hotels",
    icon: <Hotel />,
    description: "Experience Unparalleled Comfort and Luxury at Our Handpicked Selection of Hotels!",
  },
  {
    img: "assets/hikking.jpg",
    label: "Activities",
    icon: <GiCampingTent />,
    description: "Embark on Unforgettable Adventures and Memories with Our Curated Selection of Activities!",
  },
  {
    img: "assets/resturant.jpg",
    label: "Restaurants",
    icon: <Restaurant />,
    description: "Savor Exquisite Flavors and Impeccable Service at Our Carefully Selected Restaurants!",
  },
  {
    img: "assets/vehiclerent.jpg",
    label: "Vehicle Rent",
    icon: <BikeScooter />,
    description: "Explore Sri Lanka at Your Own Pace with Our Convenient and Reliable Vehicle Rental Services!",
  },
  // {
  //   img: "assets/pool_cat.jpg",
  //   label: "Amazing Pools",
  //   icon: <TbPool />,
  //   description: "This is property has a beautiful pool!",
  // },
  // {
  //   img: "assets/island_cat.webp",
  //   label: "Islands",
  //   icon: <GiIsland />,
  //   description: "This property is on an island!",
  // },
  // {
  //   img: "assets/lake_cat.webp",
  //   label: "Lakefront",
  //   icon: <GiBoatFishing />,
  //   description: "This property is near a lake!",
  // },
  // {
  //   img: "assets/skiing_cat.jpg",
  //   label: "Ski-in/out",
  //   icon: <FaSkiing />,
  //   description: "This property has skiing activies!",
  // },
  // {
  //   img: "assets/castle_cat.webp",
  //   label: "Castles",
  //   icon: <GiCastle />,
  //   description: "This property is an ancient castle!",
  // },
  // {
  //   img: "assets/cave_cat.jpg",
  //   label: "Caves",
  //   icon: <GiCaveEntrance />,
  //   description: "This property is in a spooky cave!",
  // },
  // {
  //   img: "assets/camping_cat.jpg",
  //   label: "Camping",
  //   icon: <GiForestCamp />,
  //   description: "This property offers camping activities!",
  // },
  // {
  //   img: "assets/arctic_cat.webp",
  //   label: "Arctic",
  //   icon: <BsSnow />,
  //   description: "This property is in arctic environment!",
  // },
  // {
  //   img: "assets/desert_cat.webp",
  //   label: "Desert",
  //   icon: <GiCactus />,
  //   description: "This property is in the desert!",
  // },
  // {
  //   img: "assets/barn_cat.jpg",
  //   label: "Barns",
  //   icon: <GiBarn />,
  //   description: "This property is in a barn!",
  // },
  // {
  //   img: "assets/lux_cat.jpg",
  //   label: "Luxury",
  //   icon: <IoDiamond />,
  //   description: "This property is brand new and luxurious!",
  // },
];

export const hoteltypes = [
  {
    name: "An entire place",
    description: "Guests have the whole place to themselves",
    icon: <FaHouseUser />,
  },
  {
    name: "Room(s)",
    description:
      "Guests have their own room in a house, plus access to shared places",
    icon: <BsFillDoorOpenFill />,
  },
  {
    name: "A Shared Room",
    description:
      "Guests sleep in a room or common area that maybe shared with you or others",
    icon: <FaPeopleRoof />,
  },
];

export const hotelservices=[
  {
    name: "Great for activities",
    image: require("./images/tent.svg").default,
  },
  {
    name: "Great View",
    image: require("./images/beach.svg").default,
  },
  {
    name: "Food and Drinks",
    image: require("./images/food.svg").default,
  },
  {
    name: "Check-in [24-hour]",
    image: require("./images/time.svg").default,
  },
  {
    name: "Sports & Entertainment",
    image: require("./images/game.svg").default,
  },
  {
    name: "Wellness",
    image: require("./images/spa.svg").default,
  },
  {
    name: "Airport Transfer",
    image: require("./images/airport.svg").default,
  },
];

export const activitytypes = [
  {
    name: "Camping",
    description: "Guests have the whole place to themselves",
    icon: <GiCampingTent/>,
  },
  {
    name: "Hiking",
    description:
      "Guests have their Hikking, plus access to shared places",
    icon: <Hiking />,
  },
  {
    name: "Adventure Activities",
    description:
      "Guests Adventure Activities area that maybe shared with you or others",
    icon: <TbShiJumping  />,
  },
  {
    name: "Cycling",
    description:
      "Guests Cycling that maybe shared with you or others",
    icon: <BiCycling />,
  },
  {
    name: "Bungee Jumping",
    description:
      "Guests Bungee Jumping that maybe shared with you or others",
    icon: <GiJumpingRope/>,
  },
];


export const vehicleTypes = [
  {
    name: "Motor Bike",
    icon: <TbMotorbike/>,
  },
  
  {
    name: "Car",
    icon: <PiCar/>,
  },
  {
    name: "Van",
    icon: <PiVan/>,
  },
];

export const extras = [
  {
    name: "AC Front",
  },
  {
    name: "CD",
  },
  {
    name: "TV",
  },
  {
    name: "Power Mirrors",
  },
  {
    name: "Power Locks",
  },
  {
    name: "Airbag Driver",
  },
  {
    name: "Security System",
  },
  {
    name: "Rear Defroster",
  },
  {
    name: "Alloys",
  },
  {
    name: "Spare Wheel",
  },
  {
    name: "AC Rear",
  },
  {
    name: "DVD",
  },
  {
    name: "Navigation",
  },
  {
    name: "Retract Mirrors",
  },
  {
    name: "Keyless Entry",
  },
  {
    name: "Airbag Passenger",
  },
  {
    name: "Rear Viper",
  },
  {
    name: "Sun Roof",
  },
  {
    name: "Tyre Repair Kit",
  },
  {
    name: "Cruise Control",
  },
  {
    name: "USD",
  },
  {
    name: "Power Steering",
  },
  {
    name: "Power Shutters",
  },
  {
    name: "Multi Functional Steering",
  },
  {
    name: "Airbag Side",
  },
  {
    name: "Fog Lights",
  },
  {
    name: "Tinted Glass",
  },
  {
    name: "Tow Pack",
  },
  {
    name: "Spolier",
  },
  {
    name: "None",
  }
];

export const vehifeatures = [
  {
    name: "Luxury",
  },
  {
    name: "Convertible",
  },
  {
    name: "4x4",
  },
  {
    name: "Hybird",
  },
  {
    name: "ABS (braking)",
  },
  {
    name: "Nano",
  },
  {
    name: "Budget",
  },
  {
    name: "Petrol",
  },
  {
    name: "Diesel",
  },
  {
    name: "GPS nav",
  }
];


export const facilities = [
  {
    name: "Bath tub",
    icon: <PiBathtubFill />,
  },
  {
    name: "Personal care products",
    icon: <FaPumpSoap />,
  },
  {
    name: "Outdoor shower",
    icon: <FaShower />,
  },
  {
    name: "Washer",
    icon: <BiSolidWasher />,
  },
  {
    name: "Dryer",
    icon: <BiSolidDryer />,
  },
  {
    name: "Hangers",
    icon: <PiCoatHangerFill />,
  },
  {
    name: "Iron",
    icon: <TbIroning3 />,
  },
  {
    name: "TV",
    icon: <PiTelevisionFill />,
  },
  {
    name: "Dedicated workspace",
    icon: <BsPersonWorkspace />
  },
  {
    name: "Air Conditioning",
    icon: <BsSnow />,
  },
  {
    name: "Heating",
    icon: <GiHeatHaze />,
  },
  {
    name: "Security cameras",
    icon: <GiCctvCamera />,
  },
  {
    name: "Fire extinguisher",
    icon: <FaFireExtinguisher />,
  },
  {
    name: "First Aid",
    icon: <BiSolidFirstAid />,
  },
  {
    name: "Wifi",
    icon: <BiWifi />,
  },
  {
    name: "Cooking set",
    icon: <FaKitchenSet />,
  },
  {
    name: "Refrigerator",
    icon: <BiSolidFridge />,
  },
  {
    name: "Microwave",
    icon: <MdMicrowave />,
  },
  {
    name: "Stove",
    icon: <GiToaster />,
  },
  {
    name: "Barbecue grill",
    icon: <GiBarbecue />,
  },
  {
    name: "Outdoor dining area",
    icon: <FaUmbrellaBeach />,
  },
  {
    name: "Private patio or Balcony",
    icon: <MdBalcony />,
  },
  {
    name: "Camp fire",
    icon: <GiCampfire />,
  },
  {
    name: "Garden",
    icon: <MdYard />,
  },
  {
    name: "Free parking",
    icon: <AiFillCar />,
  },
  {
    name: "Self check-in",
    icon: <FaKey />
  },
  {
    name: " Pet allowed",
    icon: <MdPets />
  }
];
export const resFacilities = [
 
  {
    name: "Washer",
    icon: <BiSolidWasher />,
  },
  {
    name: "Dryer",
    icon: <BiSolidDryer />,
  },

  {
    name: "Washroom",
    icon: <FaToilet />,
  },

  {
    name: "TV",
    icon: <PiTelevisionFill />,
  },

  {
    name: "Air Conditioning",
    icon: <BsSnow />,
  },

  {
    name: "Security cameras",
    icon: <GiCctvCamera />,
  },
  {
    name: "Fire extinguisher",
    icon: <FaFireExtinguisher />,
  },
  {
    name: "First Aid",
    icon: <BiSolidFirstAid />,
  },
  {
    name: "Wifi",
    icon: <BiWifi />,
  },

 
  {
    name: "Outdoor dining area",
    icon: <FaUmbrellaBeach />,
  },
  {
    name: "Private patio or Balcony",
    icon: <MdBalcony />,
  },
 
  {
    name: "Garden",
    icon: <MdYard />,
  },
  {
    name: "Free parking",
    icon: <AiFillCar />,
  },
  
  {
    name: " Pet allowed",
    icon: <MdPets />
  }
];

export const foods =[
    {
      name: "African cuisine"
    },
    {
      name: "American cuisine"
    },
    {
      name: "Caribbean cuisine"
    },
    {
      name: "Chinese cuisine"
    },
    {
      name: "Indian cuisine"
    },
    {
      name: "Pakistani cuisine"
    },
    {
      name: "Indonesian cuisine"
    },
    {
      name: "European cuisine"
    },
    {
      name: "Oceanic cuisine"
    },
    {
      name: "Srilankan cuisine"
    },
];

export const dinings =[
  {
    name: "Fast Food"
  },
  {
    name: "Casual"
  },
  {
    name: "Fast Casual"
  },
  {
    name: "Contemporary Casual"
  },
  {
    name: "Fine Dining"
  },
  {
    name: "Cafes and Coffee Shops"
  },
  {
    name: "Specialty Drinks"
  },
  {
    name: "Buffet"
  },
  {
    name: "Food Trucks"
  },
  {
    name: "Concession Stands"
  },
  {
    name: "Pop-Ups"
  },
  {
    name: "Ghost Restaurants"
  },
];

export const activityPackages = [

  {
    name: "Camp fire",
    icon: <GiCampfire />,
  },
 
  {
    name: "Free parking",
    icon: <AiFillCar />,
  },

  {
    name: "Tent Rental",
    icon: <FaUmbrellaBeach />,
  },
  {
    name: "Campfire Setup",
    icon: <GiCampfire />,
  },
  {
    name: "Barbecue grill",
    icon: <GiBarbecue />,
  },
  {
    name: "Cooking Equipment",
    icon: <FaKitchenSet />,
  },
  {
    name: "Camping Gear",
    icon: <MdBalcony />,
  },
  {
    name: "Guided Tours",
    icon: <FaKey />,
  },

  {
    name: "First Aid Kits",
    icon: <BiSolidFirstAid />,
  },
  {
    name: "Food Packages",
    icon: <BiSolidFridge />,
  },
  {
    name: "Entertainment",
    icon: <PiTelevisionFill />,
  },
  {
    name: "Trail Maps",
    icon: <BiWifi />,
  },
  {
    name: "Guided Hikes",
    icon: <FaKey />,
  },
  {
    name: "Safety Gear",
    icon: <GiToaster />,
  },
  {
    name: "Water Stations",
    icon: <MdMicrowave />,
  },
  {
    name: "Rest Areas",
    icon: <FaUmbrellaBeach />,
  },
  {
    name: "Wildlife Information",
    icon: <MdYard />,
  },
  {
    name: "Hiking Gear Rental",
    icon: <MdBalcony />,
  },
  {
    name: "Snacks and Drinks",
    icon: <BiSolidFridge />,
  },
  {
    name: "Bike Rentals",
    icon: <AiFillCar />,
  },
  {
    name: "Repair Stations",
    icon: <BiSolidWasher />,
  },
  {
    name: "Bike Storage",
    icon: <PiCoatHangerFill />,
  },
  {
    name: "Nutrition Packs",
    icon: <FaPumpSoap />,
  },
  {
    name: "Professional Guides",
    icon: <FaKey />,
  },
  {
    name: "Safety Briefings",
    icon: <BiSolidFirstAid />,
  },
  {
    name: "Photo and Video Packages",
    icon: <PiTelevisionFill />,
  },
  
  {
    name: "Refreshments",
    icon: <BiSolidFridge />,
  },
  {
    name: "Merchandise",
    icon: <PiCoatHangerFill />,
  },
  {
    name: "Transport Services",
    icon: <AiFillCar />,
  },
  {
    name: "Professional Instructors",
    icon: <FaKey />,
  },
  {
    name: "Pre-Jump Training",
    icon: <BiSolidFirstAid />,
  },
  {
    name: "Spectator Area",
    icon: <FaUmbrellaBeach />,
  },
  
  {
    name: "Activity Insurance",
    icon: <FaFireExtinguisher />,
  },
  {
    name: "Accessibility Facilities",
    icon: <BsPersonWorkspace />,
  },
  {
    name: "Help Desk",
    icon: <GiCctvCamera />,
  },
 
  
  {
    name: "Fire extinguisher",
    icon: <FaFireExtinguisher />,
  },
  {
    name: "First Aid",
    icon: <BiSolidFirstAid />,
  },
  {
    name: "Wifi",
    icon: <BiWifi />,
  },
  {
    name: "Cooking set",
    icon: <FaKitchenSet />,
  },
 
  {
    name: "Microwave",
    icon: <MdMicrowave />,
  },
  {
    name: "Stove",
    icon: <GiToaster />,
  },
  
  
 
];