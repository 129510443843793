import React from 'react';
import '../styles/ContactUs.css';
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import CommonSection from "../shared/commonSection/CommonSection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';

const ContactUsPage = () => {
  return (
    <>
      <Navbar />
      <section className='contact-us'>
        <div>
          <CommonSection title={"Contact Us"} />
        </div>
        <div className="contact-us-container">
          <div className="contact-details">
            <h2>Contact Us</h2>
            
            <div className="icon-detail-container">
              <div className="icon-background">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="white-icon" />
              </div>
              <p><strong>Address:</strong> <br></br>606, shakthi road , anpuvalipuram trincomalee</p>
            </div>
            <div className="icon-detail-container">
              <div className="icon-background">
                <FontAwesomeIcon icon={faPhone} className="white-icon" />
              </div>
              <p><strong>Phone:</strong><br></br> +94 70 351 7825</p>
            </div>
            <div className="icon-detail-container">
              <div className="icon-background">
                <FontAwesomeIcon icon={faEnvelope} className="white-icon" />
              </div>
              <p><strong>Email:</strong> <br></br>contact@explorelanka.com</p>
            </div>
            <div className="icon-detail-container">
              <div className="icon-background">
                <FontAwesomeIcon icon={faClock} className="white-icon" />
              </div>
              <p><strong>Working Hours:</strong><br></br> Monday - Friday: 9 AM - 6 PM</p>
            </div>
          </div>
          <div className="contact-form">
            <h2>GET IN TOUCH WITH US</h2>
            <p></p>
            <form>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Your Name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">E-mail:</label>
                <input type="email" id="email" name="email" className='detail' placeholder="E-mail" required />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject:</label>
                <input type="text" id="subject" name="subject" placeholder="Subject" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <button type="submit">Send Message</button>
            </form>
          </div>
          
        </div>
        <div className="contact-map">
           
            {/* Embed Google Maps iframe */}
            <iframe
title="Google Maps Location"
width="100%"
height="500"
frameBorder="0"
scrolling="no"
marginHeight="0"
marginWidth="0"
src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15865.537073529004!2d80.126134!3d9.676136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae3abfbf9f0f579%3A0x1008cd1432f11544!2sMadduvil%20South%2C%20Chavakachcheri!5e0!3m2!1sen!2slk!4v1622104185133!5m2!1sen!2slk`}
            ></iframe>
          </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUsPage;
