import { useState, useEffect, useRef } from "react";
import "../styles/ListingCard.css";
import {
  ArrowForwardIos,
  ArrowBackIosNew,
  Favorite,
  Star,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setWishList } from "../redux/state";
import Categories from './Categories';

const PropertyCard = ({
  listingId,
  creator,
  listingPhotoPaths,
  hotelPhotos,
  imgUrls,
  starRating,
  category,
  city,
  placeView,
  title,
  hoteltitle,
  model,
  province, 
  type,
}) => {
  /* SLIDER FOR IMAGES */
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + (category === "Hotel" ? hotelPhotos.length : imgUrls.length)) % (category === "Hotel" ? hotelPhotos.length : imgUrls.length)
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % (category === "Hotel" ? hotelPhotos.length : imgUrls.length)
    );
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ADD TO WISHLIST */
  const user = useSelector((state) => state.user);
  const titleRef = useRef(null);
  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
  useEffect(() => {
    const checkTitleOverflow = () => {
      if (titleRef.current) {
        const isOverflowing =
          titleRef.current.scrollWidth > titleRef.current.clientWidth;
        setIsTitleOverflowing(isOverflowing);
      }
    };

    window.addEventListener("resize", checkTitleOverflow);
    checkTitleOverflow(); // Initial check
    return () => {
      window.removeEventListener("resize", checkTitleOverflow);
    };
  }, []);

  const getTitle = () => {
    if (category === "Hotel") {
      return hoteltitle;
    } else if (category === "Vehicle Rent") {
      return model;
    } else {
      return title;
    }
  };

  const displayTitle = getTitle();

  const truncatedTitle = isTitleOverflowing
    ? displayTitle.slice(0, 15) + "..." // Adjust the number of characters as needed
    : displayTitle;

    const getPathVariable = () => {
        switch (category) {
          case "Hotel":
            return "hotel";
          case "Activities":
            return "activities";
          case "Restaurant":
            return "restaurants";
            case "Vehicle Rent":
            return "rentvehicle";
          default:
            return category.toLowerCase();
        }
      };
    
      const handleClick = () => {
        const pathVariable = getPathVariable();
        navigate(`/${pathVariable}/detail/${listingId}`);
      };

  return (
    <div className="listing-container">
      <div
        className="listing-card"
        onClick={handleClick}
      >
        <div className="slider-container">
          <div
            className="slider"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {(category === "Hotel" ? hotelPhotos : imgUrls)?.map((photo, index) => (
              <div key={index} className="slide">
                <img
                  src={photo}
                  alt={`photo ${index + 1}`}
                />
                <div
                  className="prev-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToPrevSlide(e);
                  }}
                >
                  <ArrowBackIosNew sx={{ fontSize: "15px" }} />
                </div>
                <div
                  className="next-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToNextSlide(e);
                  }}
                >
                  <ArrowForwardIos sx={{ fontSize: "15px" }} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="list-details">
          <h4 ref={titleRef} className="title">
            {truncatedTitle}
          </h4>
          <h5><span>Category:</span>{category}</h5>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
