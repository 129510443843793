import { useState,useEffect, useRef  } from "react";
import "../styles/ListingCard.css";
import {
  ArrowForwardIos,
  ArrowBackIosNew,
  Favorite,
  Star,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setWishList } from "../redux/state";

const ListingCard = ({
  listingId,
  creator,
  listingPhotoPaths,
  hotelPhotos,
  starRating,
  city,
  placeView,
  hoteltitle,
  province, 
  category,
  type,
 
}) => {
  /* SLIDER FOR IMAGES */
  const [currentIndex, setCurrentIndex] = useState(0);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const goToPrevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + hotelPhotos.length) % hotelPhotos.length
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % hotelPhotos.length);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ADD TO WISHLIST */
  const user = useSelector((state) => state.user);
  const wishList = user?.wishList || [];

  const isLiked = wishList?.find((item) => item?._id === listingId);
  const titleRef = useRef(null);
  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false);
  useEffect(() => {
    const checkTitleOverflow = () => {
      if (titleRef.current) {
        const isOverflowing =
          titleRef.current.scrollWidth > titleRef.current.clientWidth;
        setIsTitleOverflowing(isOverflowing);
      }
    };

    window.addEventListener("resize", checkTitleOverflow);
    checkTitleOverflow(); // Initial check
    return () => {
      window.removeEventListener("resize", checkTitleOverflow);
    };
  }, []);

  const truncatedTitle = isTitleOverflowing
  ? hoteltitle.slice(0, 15) + "..." // Adjust the number of characters as needed
  : hoteltitle;

const handleClick = () => {
  navigate(`/restaurants/detail/${listingId}`);
};

const patchWishList = async () => {
  if (user?._id !== creator._id) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/${user?._id}/wishlist/${listingId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      dispatch(setWishList(data.wishList));
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  }
};

const handleFavoriteClick = (e) => {
  e.stopPropagation();
  patchWishList();
  // Optimistically update the UI
  const updatedWishList = isLiked
    ? wishList.filter((item) => item._id !== listingId)
    : [...wishList, { _id: listingId }];
  dispatch(setWishList(updatedWishList));
};

  return (
    <div className="listing-container">
    <div
      className="listing-card"
      onClick={() => {
        
        navigate(`/${category}/detail/${listingId}`);
      }}
    >
      <div className="slider-container">
        <div
          className="slider"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {hotelPhotos?.map((photo, index) => (
              <div key={index} className="slide">
                <img
                  src={photo}
                  alt={`photo ${index + 1}`}
                  
                />
              <div
                className="prev-button"
                onClick={(e) => {
                  e.stopPropagation();
                  goToPrevSlide(e);
                }}
              >
                <ArrowBackIosNew sx={{ fontSize: "15px" }} />
              </div>
              <div
                className="next-button"
                onClick={(e) => {
                  e.stopPropagation();
                  goToNextSlide(e);
                }}
              >
                <ArrowForwardIos sx={{ fontSize: "15px" }} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="list-details">
      <h3 ref={titleRef} className="title">
            {truncatedTitle}
          </h3>
      <p>{city}, {province}</p>
      <p>{type}</p>
      <p>
          {Array.from({ length: starRating }, (_, index) => (
            <Star key={index} sx={{ color: "gold" }} />
          ))}
        </p>
        <p className="placeview">{placeView} Available</p></div>
  

      {/* {!booking ? (
        <>
          
          
        </>
      ) : (
        <>
          <p>
            {startDate} - {endDate}
          </p>
          <p>
            <span>${totalPrice}</span> total
          </p>
        </>
      )} */}

      <button
          className="favorite"
          onClick={handleFavoriteClick}
          disabled={!user}
        >
          {isLiked ? (
            <Favorite sx={{ color: "red" }} />
          ) : (
            <Favorite sx={{ color: "white" }} />
          )}
        </button>
    </div>
    </div>
  );
};

export default ListingCard;
