import "../styles/Footer.css"
import { LocationOn, LocalPhone, Email, Facebook, Twitter, YouTube, Instagram, ChevronRightTwoTone } from "@mui/icons-material"
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// const Footer = () => {
//   return (
//     <div className="footer">
//       <div className="footer_left">
//         <a href="/"><img src="/assets/logo.png" alt="logo" /></a>
//       </div>

//       <div className="footer_center">
//         <h3>Useful Links</h3>
//         <ul>
//           <li>About Us</li>
//           <li>Terms and Conditions</li>
//           <li>Return and Refund Policy</li>
//         </ul>
//       </div>

//       <div className="footer_right">
//         <h3>Contact</h3>
//         <div className="footer_right_info">
//           <LocalPhone />
//           <p>+94 70 351 7825</p>
//         </div>
//         <div className="footer_right_info">
//           <Email />
//           <p>dreamnest@support.com</p>
//         </div>
//         <img src="/assets/payment.png" alt="payment" />
//       </div>
//     </div>

    
//   )
// }

// export default Footer

import React from 'react';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-info">
        <div className="contact-item">
          <div className="icon-container">
           <LocalPhone className="icontop"/>
          </div>
          <div>
            <h3>Call us</h3>
            <p>+94 70 351 7825</p>
          </div>
        </div>
        <div className="contact-item">
          <div className="icon-container">
            <Email className="icontop"/>
          </div>
          <div>
            <h3>Write to us</h3>
            <p>info@exploringlanka.com</p>
          </div>
        </div>
        <div className="contact-item">
          <div className="icon-container">
            <LocationOn className="icontop"/>
          </div>
          <div>
            <h3>Address</h3>
            <p>606, shakthi road , anpuvalipuram trincomalee</p>
          </div>
        </div>
      </div>
      <div className="footer-content">
        <div className="footer-section">
          <img src="/assets/logo.png" alt="Travol" className="logo" />
          <p>
            Discover the rich culture, stunning landscapes, and vibrant wildlife of Sri Lanka with us.
          </p>
          <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61558661799506&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <Facebook className="icon"/>
          </a>
          <a href="https://www.instagram.com/exploring._lanka?igsh=djU2YnV2dHFlemhw&utm_source=qr" target="_blank" rel="noopener noreferrer">
            <Instagram className="icon"/>
          </a>
          <a href="https://www.tiktok.com/@exploringlanka1?_t=8n18ajMtnc7&_r=1" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} className='icon' />
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <YouTube className="icon"/>
          </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
          <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/about">About</a></li>
            <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/product">Products</a></li>
            <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/blog">Blog</a></li>
            <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/contactus">Contact Us</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Services</h3>
          <ul>
          <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/properties/category/hotel">Hotels</a></li>
          <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/properties/category/restaurant">Restaurants</a></li>
          <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/properties/category/vehiclerent">Vehicle Rent</a></li>
          <li><ChevronRightTwoTone className="quick-link-icon" /><a href="/properties/category/activity">Activities</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Subscribe</h3>
          <p>
            Sign up for our monthly blogletter to stay informed
            about travel and tours
          </p>
          <div className="subscribe">
            <input type="email" placeholder="Email Address" />
            <button type="submit">Send</button>
          </div>
        </div>
      </div>
	<div className="foot-last">©2024 Exploring Lanka. All rights reserved. </div>
    </footer>
  );
};

export default Footer;
